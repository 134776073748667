import axios from "../../base/axiosconfig";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import  useGetDetallePersona  from "../../hooks/Personas/useGetDetallePersona";
import { LoadingAction } from "../../redux/actions/Configuracion/LoadingActions";
import MiniLoading  from "../../components/MiniLoading";
import swal from 'sweetalert'
import { PersonasAction } from "../../redux/actions/Personas/personasActions";
import { DetallePersonaAction } from "../../redux/actions/Personas/detallePersonaActions";
import { EmpresaDetalleAction, EmpresasAction } from "../../redux/actions/Empresas/empresasActions";
import useGetEmpresaByRNC from "../../hooks/Empresas/useGetEmpresaByRNC";

const CardInfoPersonasVehiculo = (props: any) => {

  const {getDetallePersona} = useGetDetallePersona()

  const {getEmpresaByRNC} = useGetEmpresaByRNC()

  const [Persona, setPersona] = useState({
    personaId: -1,
    documento:'',
    nombres:'',
    apellido1:'',
    apellido2:'',
    nombrecompleto:'',
    fhNacimiento:new Date,
    edad:-1,
    sexo:'',
    genero:'',
    fhCreacion:new Date,
    lugarNacimiento:'',
    nacionalidadDescripcion: '',
    estatusDescripcion:'',
    fotoRuta:'',
    correos:[],
    direcciones:[],
    telefonosPersona:[],
    vehiculos:[]
  })
  const [Empresa, setEmpresa] = useState({
    empresaId:-1,
    empresaIdentificacion :'',
    empresaNombre:'',
    empresaNombreComercial:'',
    actividadComercialNombre:'',
    fhIncorporacion:new Date ,
    fhCancelacion:new Date,
    tipoEmpresa:'',
    fhCreacion:new Date,
  })

  let history = useHistory();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(true)

  useEffect(() => {
    
    GetinfoEntidad()
    dispatch(LoadingAction(false))
  }, [props.entidad.entidadId])

  const SetPersonDetalle = () =>{
    dispatch(PersonasAction([]))
    dispatch(DetallePersonaAction(Persona))
    dispatch(EmpresasAction([]))
    dispatch(EmpresaDetalleAction(Empresa))
    dispatch(LoadingAction(true))
    getDetallePersona(props.entidad.entidadId)
    .then(() =>
      { 
        history.push("/Home/DetallePersona")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consulta Personas", error, "info")
        dispatch(LoadingAction(false))
      });
  }

  const SetEmpresaDetalle = () =>{
    dispatch(PersonasAction([]))
    dispatch(DetallePersonaAction(Persona))
    dispatch(EmpresasAction([]))
    dispatch(EmpresaDetalleAction(Empresa))
    dispatch(LoadingAction(true))
    dispatch(LoadingAction(true));
    getEmpresaByRNC(props.entidad.documento)
    .then(()=> 
    {
      history.push("/Home/DetalleEmpresa")
      dispatch(LoadingAction(false))
    })
    .catch(error=>
    { 
      dispatch(LoadingAction(false))
      swal("Busqueda Empresa", error, "info")
    });
  }

  
  const GetinfoEntidad = () =>{

    if (props.entidad.entidadTipo == 1 ) {
      axios.get(`/api/Personas/DetallePersona/${props.entidad.entidadId}`)
      .then(function (response) {
        setPersona(response.data)
        setloading(false)
      })
      .catch(function (error) {
          console.log(error);
          setloading(false)
      })
    }
    
    if(props.entidad.entidadTipo == 2 ) {
      
      axios.get(`/api/Busqueda/GetEmpresasByIdentificacion/${props.entidad.documento}`,)
      .then( (response)=> {
        setEmpresa(response.data[0])
        setloading(false)
      })
      .catch( (error)=> {
        console.log(error)
        setloading(false)
      });
  }
  }

  const Loading = () =>{
    return (
      <div className="CardInfoPersonas">
          <MiniLoading/>
      </div>
    )
  }
  
  const TablePersona = () =>{
    return (
    <div className="CardInfoPersonas" onClick={()=>SetPersonDetalle()}>
      <div>
        <img src={Persona.fotoRuta} />
      </div>
      <div>
        <div>
          <h6>Nombres</h6>
          <p>{Persona.nombres}</p>
        </div>
        <div>
          <h6>Apellidos</h6>
          <p>{Persona.apellido1} {Persona.apellido2}</p>
        </div>
       <div>
          <h6>Cédula</h6>
          <p>{Persona.documento}</p>
        </div>
        <div>
          <h6>Edad</h6>
          <p>{Persona.edad} años</p>
        </div>
        <div>
          <h6>Genero</h6>
          <p>{Persona.genero}</p>
        </div>
        <div>
          <h6>Lugar de nacimiento</h6>
          <p>{Persona.lugarNacimiento}</p>
        </div>
        <div>
          <h6>Fecha Relacion</h6>
          <p>{props.entidad.vehiculoFhRelacion}</p>
        </div>
        
      </div>
    </div>
    )
  }

  const TableEmpresa= () =>{
    return (
      <div className="CardResultadoPersona" onClick={()=>SetEmpresaDetalle()}>
      <div>
        <span className="material-icons">apartment</span>
      </div>
    <div>
    
    <div>
          <h6>RNC</h6>
          <p>{Empresa.empresaIdentificacion}</p>
        </div>
      <div>
          <h6>Nombre</h6>
          <p>{Empresa.empresaNombre}</p>
        </div>
        <div>
          <h6>Nombre Comercial</h6>
          <p>{Empresa.empresaNombreComercial}</p>
        </div>
        <div>
          <h6>Actividad Comercial</h6>
          <p>{Empresa.actividadComercialNombre}</p>
        </div>
        <div>
          <h6>Tipo de Empresa</h6>
          <p>{Empresa.tipoEmpresa}</p>
        </div>
        <div>
          <h6>Fecha Relacion</h6>
          <p>{props.entidad.vehiculoFhRelacion}</p>
        </div>
    </div>
  </div>
    )
  }

  return (
    <div>
    {
      loading
      ?
      <div style={{display: 'flex',  justifyContent:'center'}}>
        <Loading/>
      </div>
      :
        props.entidad.entidadTipo == 1 
        ?
          <TablePersona/>
        :
          <TableEmpresa/>
    }
  </div>


  );
};

export default CardInfoPersonasVehiculo;
