import { useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import  Contenedor  from '../../components/Contenedor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from '../../components/Select';
import swal from 'sweetalert'
import useGetPersonas from '../../hooks/Personas/useGetPersonas';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { VehiculoDetalleAction, VehiculosAction } from '../../redux/actions/Vehiculos/vehiculosActions';
import { EmpresaDetalleAction, EmpresasAction } from '../../redux/actions/Empresas/empresasActions';
import { IEmpresa } from '../../redux/interfaces/Empresas/IEmpresa';

let Empresa : IEmpresa ={
  empresaId:-1,
  empresaIdentificacion :'',
  empresaNombre:'',
  empresaNombreComercial:'',
  actividadComercialNombre:'',
  fhIncorporacion:new Date ,
  fhCancelacion:new Date,
  tipoEmpresa:'',
  fhCreacion:new Date,
}

const  BusquedaPersonas = () => {

    const {getPersonas} = useGetPersonas();

    const history = useHistory();
    const dispatch = useDispatch();
    const [TipoDocumento,SetTipoDocumento] = useState(1)
    const [Documento,SetDocumento] = useState('')
    const [Nombres,SetNombres] = useState('')
    const [Apellido1,SetApellido1] = useState('')
    const [Apellido2,SetApellido2] = useState('')
    const [Sexo,SetGenero] = useState('')
    const [EdadInicio, SetEdadInicio] = useState(0)
    const [EdadFin, SetEdadFin] = useState(0)
    const [LugarNacimiento,SetLugarNacimiento] = useState('')

    const HundlerInput = (event:any) => {
          switch (event.name) {
            case "TipoDocumento":
                SetTipoDocumento(parseInt(event.value));
              break;
              case "Documento":
                let cedulaLimpia = event.value.replaceAll("-","")
                SetDocumento(cedulaLimpia);
              break;
              case "Nombres":
                SetNombres(event.value);
              break;
              case "PrimerApellido":
                SetApellido1(event.value);
              break;
              case "SegundoApellido":
                SetApellido2(event.value);
              break;
              case "Genero":
                if(event.value === 'Seleccione un genero'){
                  SetGenero('');
                }else{
                  SetGenero(event.value);
                }
              break;
              case "EdadInicio":
                  if (event.value === '')
                      SetEdadInicio(0); else SetEdadInicio(parseInt(event.value));
              break;
              case "EdadFin":
             
                  if (event.value === '')
                      SetEdadFin(0); else SetEdadFin(parseInt(event.value));
              break;
              case "LugarNacimiento":
                SetLugarNacimiento(event.value);
              break;
          }
        };

    const setDataFiltro =  () =>{
      dispatch(EmpresasAction([]))
      dispatch(EmpresaDetalleAction(Empresa))
      dispatch(VehiculosAction([]))
      dispatch(VehiculoDetalleAction([]))
      dispatch(LoadingAction(true));
      getPersonas(
          TipoDocumento,
        Documento,
        Nombres,
        Apellido1,
        Apellido2,
        Sexo,
        EdadInicio,
        EdadFin,
        LugarNacimiento
      ).then(() =>
      { 
        history.push("/Home/ResultadoBusquedaPersona")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consulta Personas", error, "info")
        dispatch(LoadingAction(false))
      });
    }

    return (
        <div className="BuscarPersonas">
            <BreadCrumb link1="../Home/busquedaPersonas" infoContenidoBreadcrumb="Busqueda Personas"/>
            <Contenedor className="" headerName="Criterios de búsqueda" subHeaderName="FAVOR LLENAR LOS CRITERIOS DE BúSQUEDA">
                <div className="class-contenedor">
                    <Select OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} id="id" name="TipoDocumento" Placeholder="Tipo de identificacion" options={[{id:1,TipoDocumento:'Cedula'},{id:2,TipoDocumento:'Pasaporte'}]}/>
                    <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento" name="Documento" placeholder="Número de Documento" />
                    <Input OnChange={HundlerInput}label="" type="text" id="LugarNacimiento" name="LugarNacimiento" placeholder="Lugar de nacimiento" />
                    <Input OnChange={HundlerInput} label="" type="text" id="Nombres" name="Nombres" placeholder="Nombres" />
                    <Input OnChange={HundlerInput} label="" type="text" id="PrimerApellido" name="PrimerApellido" placeholder="Primer Apellido" />
                    <Input OnChange={HundlerInput} label="" type="text" id="SegundoApellido" name="SegundoApellido" placeholder="Segundo Apellido" />
                    <Input OnChange={HundlerInput}label="" type="number" id="EdadInicio" name="EdadInicio" placeholder="Edad Desde" />
                    <Input OnChange={HundlerInput} label="" type="number" id="EdadFin" name="EdadFin" placeholder="Edad Hasta" />
                    <Select OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} id="id" name="Genero"  Placeholder="Genero" options={[{id:'M',Genero:'Hombre'},{id:'F',Genero:'Mujer'}]} />
                    <div>
                    <Button OnClick={() => setDataFiltro()} id="BuscarPersonaID" name="BuscarPersonaName" insideNameButton="Buscar Persona" FontIconClass="search" />
                    </div>
                </div>
            </Contenedor>
        </div>
        );
    }


export default BusquedaPersonas;
