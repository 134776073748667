import { SET_LOADING } from "../../actions/actionTypes";
import { LoadingActionType, LoadingStateType } from "../../actions/Configuracion/LoadingActions";

  const initialStateposts: LoadingStateType = {
    Visible: false
  };
  
const LoadingReducer = (state = initialStateposts, action: LoadingActionType): LoadingStateType => {
    switch (action.type) {
      case SET_LOADING:
        return {
          ...state,
          Visible: action.payload
        };
      default:
        return state;
    }
  }

  export default LoadingReducer;