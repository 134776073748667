const Input = (props : any) =>{

  function handleChange(e:any) {
    props.OnChange(e.target);
  }
    return (
      <input
        disabled={props.disabled}
        value={props.value}
        maxLength={props.maxLength}
        onChange={handleChange}
        type={props.type}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
      />
    );
}

export default Input