import { GET_USUARIO } from "../actionTypes";

export interface UsuarioStateType {
    Usuario: any;
}

export interface UsuarioActionType {
  type: typeof GET_USUARIO;
  payload: any;
}

export const UsuarioAction = (Usuario: any): UsuarioActionType => {
  return {
    type: GET_USUARIO,
    payload: Usuario
  };
};