import { useDispatch } from "react-redux";
import axios from "../../base/axiosconfig";
import { VehiculoDetalleAction } from "../../redux/actions/Vehiculos/vehiculosActions";
import { IVehiculos } from "../../redux/interfaces/Vehiculos/IVehiculos";

let Vehiculos : IVehiculos[] = []

const useGetDetalleVehiculo = () => {
    const dispatch = useDispatch();
    const getDetalleVehiculo = (numeroPlaca:string,chasis:string,personaId:number,) => {
        return new Promise<any>((resolve,reject)=>{
            axios.get(`/api/Busqueda/Buscar-Vehiculo?numeroPlaca=${numeroPlaca}&chasis=${chasis}&personaId=${personaId}`,)
            .then(function (response) {
              if (response.data === "Consultas Agotadas") {
                dispatch(VehiculoDetalleAction(Vehiculos));
                reject(response.data)
              }
              if(response.data.length > 0){
                dispatch(VehiculoDetalleAction(response.data));
                resolve(()=>null)
              }else{
                dispatch(VehiculoDetalleAction(Vehiculos));
                reject("No se encontro el vehculo")
            }})
            .catch(function (error) {
              console.log(error)
              reject(error)
            });
          })
    }
    return{
        getDetalleVehiculo
    }
}
export default useGetDetalleVehiculo
