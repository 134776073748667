import { GET_ALL_USUARIOS } from "../../actions/actionTypes";
import { UsuariosActionType, UsuariosStateType } from "../../actions/Usuarios/UsuariosAction";

  const initialState: UsuariosStateType = {
    Usuarios: []
  };
  
const UsuariosReducer = (state = initialState, action: UsuariosActionType): UsuariosStateType => {
    switch (action.type) {
      case GET_ALL_USUARIOS:
        return {
          ...state,
          Usuarios: action.payload
        };
      default:
        return state;
    }
  }

  export default UsuariosReducer;