import {  GET_EMPRESA_DETALLE } from "../../actions/actionTypes";
import { DetalleEmpresasStateType, EmpresasDetalleActionType } from "../../actions/Empresas/empresasActions";

  const initialStateposts: DetalleEmpresasStateType = {
    DetalleEmpresas : {
      empresaId:-1,
      empresaIdentificacion :'',
      empresaNombre:'',
      empresaNombreComercial:'',
      actividadComercialNombre:'',
      fhIncorporacion:new Date ,
      fhCancelacion:new Date,
      tipoEmpresa:'',
      fhCreacion:new Date,
    }
  };
  
const EmpresaDetalleReducer = (state = initialStateposts, action: EmpresasDetalleActionType): DetalleEmpresasStateType => {
    switch (action.type) {
      case GET_EMPRESA_DETALLE:
        return {
          ...state,
          DetalleEmpresas: action.payload
        };
      default:
        return state;
    }
  }

  export default EmpresaDetalleReducer;