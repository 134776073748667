
import spinner from '../image/spinner.gif'


const MiniLoading = () =>{
   return (
     <div  >
          <img id="animacion-carga-mini" src={spinner} />
     </div>
   );
}

export default MiniLoading