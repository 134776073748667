import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const TabsComponent =  (props: { tabsInfo: any[]; tabsPanel: any[]; }) => {
        return (
            <div className="TabsComponent">
                <Tabs forceRenderTabPanel={true}>
                    <TabList>
                        {props.tabsInfo.map(info =>  (
                            <Tab key={info.id}>{info.nombre}</Tab>
                        ))}   
                    </TabList>
                    {props.tabsPanel.map(info =>(
                        <TabPanel  key={info.id}>{info.nombre}</TabPanel>
                    ))}
                </Tabs>
            </div>
        );
    }

export default TabsComponent;
