import React, { Component } from 'react';
import NavMenu from './NavMenu';
import Header  from '../components/header';

export class Layout extends Component {
  render () {
      return (
          <div className="class-grid-container-father">
            <NavMenu />
            <div>
                <Header/>
                <div>
                    {this.props.children}
                </div>
            </div>
          </div>
    );
  }
}
export default Layout;
