import { useState } from "react";
import  Input  from "../components/Input";
import Button  from "../components/Button";
import { LinkComponent } from "../components/link";
import axios from "../base/axiosconfig";
import Logo from "../image/logo.png";
import { useHistory } from "react-router-dom";
import swal from 'sweetalert'
import { LoadingAction } from "../redux/actions/Configuracion/LoadingActions";
import { useDispatch } from "react-redux";

const Login = () => {
  const [Usuario, SetUsuario] = useState("");
  const [Clave, SetClave] = useState("");
  let history = useHistory();
const dispatch = useDispatch()
  const HundlerInput = (event:any) => {
    switch (event.name) {
      case "UsuarioName":
          SetUsuario(event.value);
        break;
        case "ClaveName":
          SetClave(event.value);
        break;
    }
  };

  const GetUsuario = async () => {
    dispatch(LoadingAction(true));
    const url = `/api/usuarios/login?usuario=${Usuario}&clave=${Clave}`;
    await axios
      .post(url)
      .then((response) => {
        localStorage.setItem("cedula", response.data.cedula);
        localStorage.setItem("cargoUsuario", response.data.cargo);
        localStorage.setItem("nombreUsuario", response.data.nombre);
        localStorage.setItem("fecha", response.data.expiration);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("trabajoUsuario", response.data.trabajo);
        localStorage.setItem("usuarioId", response.data.usuarioId);
        history.push("/Home/busquedaPersonas");
        dispatch(LoadingAction(false));
      })
      .catch((error) => {
        dispatch(LoadingAction(false));
        swal("Login", "credenciales incorrectas", "info");
        console.log(error);
      });
  };

  return (
    <div className="loginBackground">
      <div>
        <img src={Logo} /> 
        <Input
          label=""
          OnChange={HundlerInput}
          id="UsuarioID"
          name="UsuarioName"
          placeholder="Usuario"
          type="text"
        />
        <Input
          label=""
          OnChange={HundlerInput}
          id="ClaveID"
          name="ClaveName"
          placeholder="Clave"
          type="password"
        />
        <Button
          type={'submit'}
          OnClick={GetUsuario}
          id="IniciarSeccionID"
          name="IniciarSeccionName"
          insideNameButton="Iniciar Sesión"
          FontIconClass="power_settings_new"
        />
        <LinkComponent
          href="../counter"
          infoContenido="¿Olvido su contraseña?"
          id="OlvidoClaveID"
          name="OlvidoClaveName"
        />
        {/*<RadioButton
          id="ConfirmacionAceptarTerminosYCondicionesID"
          name="ConfirmacionAceptarTerminosYCondicionesName"
          value="ConfirmacionAceptarTerminosYCondicionesValue"
          infoContenido="Al iniciar sección confirmo que acepto la"
        />
        <LinkComponent
          href="../counter"
          infoContenido="Clausula de Responsabilidad"
          id="ClausulaResponsabilidadID"
          name="ClausulaResponsabilidadName"
        />*/}
      </div>
    </div>
  );
};

export default Login;
