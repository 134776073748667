import { combineReducers } from 'redux'
import LoadingReducer from './Configuracion/LoadingReducer';
import EmpresaDetalleReducer from './Empresas/EmpresaDetalleReducer';
import EmpresaReducer from './Empresas/EmpresaReducer';
import DetallePersonaReducer from './Personas/DetallePersonaReducer';
import PersonasReducer from './Personas/PersonasReducer';
import UsuarioReducer from './Usuarios/UsuarioReducer';
import UsuariosReducer from './Usuarios/UsuariosReducer';
import VehiculosDetalleReducer from './Vehiculos/VehiculosDetalleReducer';
import VehiculosReducer from './Vehiculos/VehiculosReducer';
import PersonasTelefonoReducer from './Telefonos/PersonasTelefonoReducer';
import BusquedaMasivaReducer from './BusquedaMasiva/BusquedaMasivaReducer';

const rootReducer = combineReducers({
    personas:PersonasReducer,
    detallePersona:DetallePersonaReducer,
    vehiculos:VehiculosReducer,
    vehiculosDetalle:VehiculosDetalleReducer,
    empresas:EmpresaReducer,
    EmpresaDetalle:EmpresaDetalleReducer,
    PersonasTelefono:PersonasTelefonoReducer,
    personasBusquedaMasiva:BusquedaMasivaReducer,
    Usuarios:UsuariosReducer,
    Usuario:UsuarioReducer,
    Loading:LoadingReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer;