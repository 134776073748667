import { useDispatch } from "react-redux";
import axios from "../../base/axiosconfig";
import { EmpresaDetalleAction } from "../../redux/actions/Empresas/empresasActions";
import { IEmpresa } from "../../redux/interfaces/Empresas/IEmpresa";

let Empresa : IEmpresa ={
  empresaId:-1,
  empresaIdentificacion :'',
  empresaNombre:'',
  empresaNombreComercial:'',
  actividadComercialNombre:'',
  fhIncorporacion:new Date ,
  fhCancelacion:new Date,
  tipoEmpresa:'',
  fhCreacion:new Date,
}

const useGetEmpresaByRNC = () =>{
    
  const dispatch = useDispatch();

       const getEmpresaByRNC = (identificacion:string) => {
            return new Promise<any>((resolve, reject) => {
                axios.get(`/api/Busqueda/GetEmpresasByIdentificacion/${identificacion}`,)
                .then( (response)=> {
                  if(response.data.length > 0){
                    dispatch(EmpresaDetalleAction(response.data[0]));
                    resolve(()=>null)
                  }else{
                    dispatch(EmpresaDetalleAction(Empresa));
                    reject("No se encontro ninguna empresa")
                }})
                .catch( (error)=> {
                  console.log(error)
                  reject(error)
                });
            })
        }
        return{
          getEmpresaByRNC
        }
}

  export default useGetEmpresaByRNC;