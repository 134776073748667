import { GET_PERSONAS_CONSULTA_MASIVA} from '../../actions/actionTypes';
import { BusquedaMasivaActionType, BusquedaMasivaStateType } from '../../actions/BusquedaMasiva/BusquedaMasivaActions';
  
  const initialStateposts: BusquedaMasivaStateType = {
    personasBusquedaMasiva: []
  };
  
const BusquedaMasivaReducer = (state = initialStateposts, action: BusquedaMasivaActionType): BusquedaMasivaStateType => {
    switch (action.type) {
      case GET_PERSONAS_CONSULTA_MASIVA:
        return {
          ...state,
          personasBusquedaMasiva: action.payload
        };
      default:
        return state;
    }
  }

  export default BusquedaMasivaReducer;