import axios from 'axios';
import { API_URL } from './API_URL';

const instance = axios.create({
    baseURL:API_URL,
    //headers: localStorage.getItem('token')
})

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

export default instance