import axios from "../../base/axiosconfig";
import { IDetallePersona } from "../../redux/interfaces/Personas/IDetallePersona";
import { DetallePersonaAction } from "../../redux/actions/Personas/detallePersonaActions";
import { useDispatch } from "react-redux";

let detallePersona : IDetallePersona = {
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
}

const useGetDetallePersona = () =>{
  const dispatch = useDispatch();
    const getDetallePersona =(id:number)=>{
      return new Promise((resolve, reject) => {
        axios.get(`/api/Personas/DetallePersona/${id}`)
        .then(function (response) {
          dispatch(DetallePersonaAction(detallePersona));
            if(response.data === "Consultas Agotadas"){
              reject(response.data)
            }else{
              dispatch(DetallePersonaAction(response.data));
              resolve(()=>null)
            }
        })
        .catch(function (error) {
            console.log(error);
        })
      })
  }
  return{
    getDetallePersona
  }
}

export default useGetDetallePersona;