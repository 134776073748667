import { IPersonasTelefono } from "../../interfaces/Telefonos/IPersonasTelefono";
import { GET_PERSONAS_TELEFONO } from "../actionTypes";

export interface PersonasTelefonoStateType {
  PersonasTelefono: IPersonasTelefono[];
}

export interface PersonasTelefonoActionType {
  type: typeof GET_PERSONAS_TELEFONO;
  payload: IPersonasTelefono[];
}



export const PersonaTelefonosAction = (PersonasTelefono: IPersonasTelefono[]): any => {
    return {
      type: GET_PERSONAS_TELEFONO,
      payload: PersonasTelefono
    };
  };