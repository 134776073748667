import { Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import Logo from "../image/logo.png";
import LogoPoliciaNacional from "../image/logo-policia-nacional.png";
import Icon4 from "../icons/Page-4.svg";
import Icon5 from "../icons/search.svg";

import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import { AppState } from "../redux/reducers/rootReducer";



const NavMenu = () => {
  
  const {personasBusquedaMasiva} = useSelector((state: AppState) => state.personasBusquedaMasiva);

  function displayBlock() {
    document.getElementById("MenuID")?.classList.add("display-block");
    document.getElementById("FooterMenuID")?.classList.add("display-block");
    document.getElementById("id-navbar")?.classList.add("z-index-999");
    document.getElementById("MenuIconID")?.classList.remove("display-block");
    document.getElementById("ClearIconID")?.classList.add("display-block");
  }

  function displayNone() {
    document.getElementById("MenuID")?.classList.remove("display-block");
    document.getElementById("FooterMenuID")?.classList.remove("display-block");
    document.getElementById("id-navbar")?.classList.remove("z-index-999");
    document.getElementById("MenuIconID")?.classList.add("display-block");
    document.getElementById("ClearIconID")?.classList.remove("display-block");
  }

  return (
    <Navbar id="id-navbar">
      <div>
        <span
          className="material-icons display-block"
          onClick={displayBlock}
          id="MenuIconID"
        >
          menu
        </span>
        <span className="material-icons" onClick={displayNone} id="ClearIconID">
          clear
        </span>
        <NavbarBrand tag={Link} to="/Home">
          <img src={Logo} />
        </NavbarBrand>
      </div>
      <div id="MenuID">
        <div>
          <img className="img-absolute" src={Icon5} />
          <Collapsible trigger="Consultas" open>
            <NavItem>
              <NavLink tag={Link} to="/Home/busquedaPersonas">
                <span className="material-icons">account_circle</span>Personas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home/busquedaVehiculo">
                <span className="material-icons">directions_car</span>Vehiculo
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home/BusquedaEmpersa">
                <span className="material-icons">domain</span>Empresas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home/BusquedaTelefono">
                <span className="material-icons">receipt_long</span>Telefono
              </NavLink>
            </NavItem>
            <NavItem>
              {personasBusquedaMasiva.length > 0 ? (
                <NavLink tag={Link} to="/Home/ResultadoBusquedaMasiva">
                  <span className="material-icons">receipt_long</span>Busqueda
                  Masiva
                </NavLink>
              ) : (
                <NavLink tag={Link} to="/Home/BusquedaMasiva">
                  <span className="material-icons">receipt_long</span>Busqueda
                  Masiva
                </NavLink>
              )}
            </NavItem>
          </Collapsible>
        </div>
        <div>
          <img className="img-absolute" src={Icon4} />
          <Collapsible trigger="Configuración">
            <NavItem>
              <NavLink tag={Link} to="/Home/ListaUsuarios">
                <span className="material-icons">account_circle</span>Usuarios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home">
                <span className="material-icons">equalizer</span>Jerarquia
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home">
                <span className="material-icons">receipt_long</span>Lista de
                exclusiones
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/Home">
                <span className="material-icons">receipt_long</span>Auditoria
              </NavLink>
            </NavItem>
          </Collapsible>
        </div>
      </div>
      <div id="FooterMenuID">
        <img src={LogoPoliciaNacional} />
      </div>
    </Navbar>
  );
};

export default NavMenu;
