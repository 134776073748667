import { SET_LOADING } from "../actionTypes";

export interface LoadingStateType {
  Visible: boolean;
}

export interface LoadingActionType {
  type: typeof SET_LOADING;
  payload: boolean;
}


export const LoadingAction = 
(Visible: boolean): LoadingActionType => {
  return {
    type: SET_LOADING,
    payload: Visible
  };
};


