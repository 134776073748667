import { GET_VEHICULOS } from "../../actions/actionTypes";
import { VehiculosActionType, VehiculosStateType } from "../../actions/Vehiculos/vehiculosActions";
import { IVehiculos } from "../../interfaces/Vehiculos/IVehiculos";
  
interface InitialState {
  Vehiculos: IVehiculos[];
  }
  
  const initialState: InitialState = {
    Vehiculos: []
  };


const VehiculosReducer = (state: InitialState = initialState, action: VehiculosActionType): VehiculosStateType => {
    switch (action.type) {
        case GET_VEHICULOS:
            return {
              ...state,
              Vehiculos: action.payload
            };
      default:
        return state;
    }
}

  export default VehiculosReducer;