import { useEffect, useState } from "react";

import {
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import axios from "../base/axiosconfig";

const Header = () =>{

  const [url,seturl] = useState('')
  const [UsuarioConsultasAsignadas,setUsuarioConsultasAsignadas] = useState('')
  const [UsuarioConsultasRestantes,setUsuarioConsultasRestantes] = useState('')
  const [UsuarioConsultasConsumidas,setUsuarioConsultasConsumidas] = useState('')

  useEffect(() => {
    BuscarPersona()
    getCantidadConsultas()
  }, [])

  const BuscarPersona = () => {
    let TipoDocumento = 1
    let Documento = localStorage.getItem("cedula")
    axios.post(`/api/Personas/Buscar-Personas`, {
      TipoDocumento,
      Documento
      })
      .then(function (response) {
        seturl(response.data[0].fotoRuta)
      })
      .catch(function (error) {
          console.log(error)
      });
}

const getCantidadConsultas = () => {
  axios.get(`/api/usuarios/Get-Perfil/${localStorage.getItem("usuarioId")}`,)
    .then(function (response) {
      setUsuarioConsultasAsignadas(response.data.usuarioConsultasAsignadas)
      setUsuarioConsultasRestantes(response.data.usuarioConsultasRestantes)
      setUsuarioConsultasConsumidas(response.data.usuarioConsultasConsumidas)
    })
    .catch(function (error) {
        console.log(error)
    });
}


    return (
      <header>
        <div>
          {/*<span className="material-icons">search</span>
          <Input
            label=""
            type="text"
            id="UsuarioID"
            name="UsuarioName"
            placeholder="Usuario"
          />*/}
        </div>
        <div>
          <div>
            <h6>{localStorage.getItem('cargoUsuario')} / {localStorage.getItem('trabajoUsuario')} | {localStorage.getItem('nombreUsuario')}</h6>
              <h2>Cantidad Asignadas: {UsuarioConsultasAsignadas}, Cantidad Consumidas: {UsuarioConsultasConsumidas}, Cantidad Restantes: {UsuarioConsultasRestantes}</h2>
              <span className="material-icons">keyboard_arrow_down</span>
            <Collapsible trigger="...">
              <NavItem>
                <NavLink tag={Link} to="/">
                  Usuario
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/">
                  Jerarquia
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/">
                  Lista de exclusiones
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink  onClick={() => localStorage.clear()} tag={Link} to="/Login">
                <p> Cerrar Sesión  </p>
                </NavLink>
              </NavItem>
            </Collapsible>
          </div>
          <img src={url} />
        </div>
      </header>
    );
}

export default Header
