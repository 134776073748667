import axios from "../../base/axiosconfig";
import { useDispatch } from "react-redux";
import { BusquedaMasivaAction } from "../../redux/actions/BusquedaMasiva/BusquedaMasivaActions"

const useGetPersonasBusquedaMasiva = () =>{
    const dispatch = useDispatch();
    const getPersonasBusquedaMasiva = 
      (
        Documento:any[],
      ) => {
        return new Promise<any>((resolve, reject) => {
          axios.post(`/api/PN/ConsultasDenuncias`, 
              Documento,
            )
            .then(function (response) {
              if(response.data === "Consultas Agotadas"){
                dispatch(BusquedaMasivaAction([]));
                reject(response.data)
              }else if(response.data.length == 0) {
                dispatch(BusquedaMasivaAction([]));
                reject("No se encontro ninguna persona")
              }else{
                dispatch(BusquedaMasivaAction(response.data));
                resolve(()=>null)
              }
            })
            .catch(function (error) {
                reject(error)
            })
          })
        }
  return{
    getPersonasBusquedaMasiva
  }
}

export default useGetPersonasBusquedaMasiva