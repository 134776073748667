import { IEmpresa } from "../../interfaces/Empresas/IEmpresa";
import { GET_EMPRESA, GET_EMPRESA_DETALLE } from "../actionTypes";

export interface EmpresasStateType {
    Empresas: IEmpresa[];
}
export interface DetalleEmpresasStateType {
  DetalleEmpresas: IEmpresa;
}

export interface EmpresasActionType {
  type: typeof GET_EMPRESA;
  payload: IEmpresa[];
}

export interface EmpresasDetalleActionType {
  type: typeof GET_EMPRESA_DETALLE;
  payload: IEmpresa;
}

export const EmpresasAction = 
(Empresas: IEmpresa[]): EmpresasActionType => {
  return {
    type: GET_EMPRESA,
    payload: Empresas
  };
};


export const EmpresaDetalleAction = 
(EmpresaDetalle: IEmpresa): EmpresasDetalleActionType => {
  return {
    type: GET_EMPRESA_DETALLE,
    payload: EmpresaDetalle
  };
};

