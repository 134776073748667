import { useEffect, useState } from 'react';
import  BreadCrumb  from '../../components/breadcrumb';
import  Input  from "../../components/Input";
import Button  from "../../components/Button";
import  Paginations  from '../../components/Paginations';
import TableComponent from '../../components/Table';
import { useHistory } from 'react-router-dom';
import useGetUsuarios from '../../hooks/Usuarios/useGetUsuarios';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert'
import { UsuarioAction } from '../../redux/actions/Usuarios/UsuarioAction';

interface IUsuarios {
    usuarioid: number;
    usuarioLogin: string;
    usuarioNombres: string;
    usuarioCargo: string;
    grupoDescripcion: string;
    afiliadoDescripcion: string;
    usuarioUltimaFhSesion: Date;
  }

const ListaUsuarios = () => {

    let history = useHistory();
    const dispatch = useDispatch()
    const [Usuarios,setUsuarios] = useState<Array<IUsuarios>>([]);

    const {getUsuarios} = useGetUsuarios();

    useEffect(()=>{
        Getusuarios()
    },[])

    const editarUsuario = (usuario:any)=>{
        let usuarioselect = Usuarios.find(x=>x.usuarioid === usuario.usuarioid)
        dispatch(UsuarioAction(usuarioselect))
        history.push("/Home/EditarUsuario")
    }
    
    const Getusuarios  = () => {
        setTimeout(function(){
            getUsuarios()
            .then(resp =>
            { 
                setUsuarios(resp.data)
            })
            .catch(error => {
              swal("Consulta Personas", error, "info")
            });
        }, 5000);
    }
    
    const nameList=[
        'usuarioid',
        'usuarioLogin',
        'usuarioNombres',
        'usuarioCargo',
        'grupoDescripcion',
        'afiliadoDescripcion',
        'usuarioUltimaFhSesion',
    ]

    const tabsInfo = [
        {nombre:'No.'},
        {nombre:'Nombre Usuario'},
        {nombre:'Nombres'},
        {nombre:'Cargo'},
        {nombre:'Descripcion'},
        {nombre:'Afiliado Descripcion'},
        {nombre:'Ultima Fecha Sesion'},
    ]

       return (
        <div className="ListaPersonas">
            <BreadCrumb link1="/Home/ListaUsuarios" infoContenidoBreadcrumb="Lista Usuarios"/>
            <div>
                <h4>LISTA USUARIOS</h4>
                <div>
                    <div>
                        <Input
                            OnChange={()=>{}}
                            label=""
                            id="EscribaSuBusquedaID"
                            name="EscribaSuBusqueda"
                            placeholder="Escriba su busqueda"
                            type="text"
                        />
                        <Button
                            id="BusquedaPersonasID"
                            name="BusquedaPersonasName"
                            insideNameButton="Buscar"
                            FontIconClass="search"
                        />
                        <Button
                            OnClick={() => history.push("/Home/CrearUsuario")}
                            id="TraerTodosID"
                            name="TraerTodosName"
                            insideNameButton="Crear Usaurio"
                            FontIconClass="done"
                        />
                        <Button
                            href="#"
                            id="MostrarNoRegistrosID"
                            name="MostrarNoRegistrosName"
                            insideNameButton={"Mostrar "+10+" registros"}
                            FontIconClass="keyboard_arrow_down"
                        />
                    </div>
                    <TableComponent tabsInfo={tabsInfo} tabsPanel={Usuarios} namelist={nameList} OnChange={editarUsuario}/>
                </div>
                <Paginations/>
            </div>
        </div>
        );
}


export default ListaUsuarios;
