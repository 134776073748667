import { useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import  Contenedor  from '../../components/Contenedor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert'
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import useGetPersonasBusquedaMasiva from '../../hooks/BusquedaMasiva/useGetPersonasBusquedaMasiva';

const  BusquedaMasiva = () => {

    const {getPersonasBusquedaMasiva} = useGetPersonasBusquedaMasiva();

    const history = useHistory();
    const dispatch = useDispatch();
    const [ListDocumento,SetListDocumento] = useState<any[]>([]);
    const [Documento1,SetDocumento1] = useState("");
    const [Documento2,SetDocumento2] = useState("");
    const [Documento3,SetDocumento3] = useState("");
    const [Documento4,SetDocumento4] = useState("");
    const [Documento5,SetDocumento5] = useState("");
    const [Documento6,SetDocumento6] = useState("");
    const [Documento7,SetDocumento7] = useState("");
    const [Documento8,SetDocumento8] = useState("");
    const [Documento9,SetDocumento9] = useState("");
    const [Documento10,SetDocumento10] = useState("");

    const HundlerInput = (event:any) => {
      switch (event.name) {
          case "Documento1":
            SetDocumento1(event.value.replaceAll("-",""))
            break;
          case "Documento2":
            SetDocumento2(event.value.replaceAll("-",""))
            break;
          case "Documento3":
            SetDocumento3(event.value.replaceAll("-",""))
            break;
          case "Documento4":
            SetDocumento4(event.value.replaceAll("-",""))
            break;
          case "Documento5":
            SetDocumento5(event.value.replaceAll("-",""))
            break;
          case "Documento6":
            SetDocumento6(event.value.replaceAll("-",""))
            break;
          case "Documento7":
            SetDocumento7(event.value.replaceAll("-",""))
            break;
          case "Documento8":
            SetDocumento8(event.value.replaceAll("-",""))
            break;
          case "Documento9":
            SetDocumento9(event.value.replaceAll("-",""))
            break;
          case "Documento10":
            SetDocumento10(event.value.replaceAll("-",""))
            break;
      }

    };

    const setDataFiltro =  () =>{
      
      dispatch(LoadingAction(true));
      getPersonasBusquedaMasiva(
        [Documento1,
          Documento2,
          Documento3,
          Documento4,
          Documento5,
          Documento6,
          Documento7,
          Documento8,
          Documento9,
          Documento10]
      ).then(() =>
      { 
        history.push("/Home/ResultadoBusquedaMasiva")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consulta Personas", error, "info")
        dispatch(LoadingAction(false))
      });
    }

    return (
        <div className="BuscarPersonas">
            <BreadCrumb link1="../Home/BusquedaMasiva" infoContenidoBreadcrumb="Busqueda Masiva"/>
            <Contenedor className="" headerName="Criterios de búsqueda" subHeaderName="FAVOR LLENAR LOS CRITERIOS DE BúSQUEDA">
                <div className="class-contenedor">
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento1" name="Documento1" placeholder="Número de Documento 1" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento2" name="Documento2" placeholder="Número de Documento 2" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento3" name="Documento3" placeholder="Número de Documento 3" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento4" name="Documento4" placeholder="Número de Documento 4" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento5" name="Documento5" placeholder="Número de Documento 5" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento6" name="Documento6" placeholder="Número de Documento 6" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento7" name="Documento7" placeholder="Número de Documento 7" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento8" name="Documento8" placeholder="Número de Documento 8" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento9" name="Documento9" placeholder="Número de Documento 9" />
                  <Input OnChange={HundlerInput} maxLength="13" label="" type="text" id="Documento10" name="Documento10" placeholder="Número de Documento 10" />
                  <Button OnClick={() => setDataFiltro()} id="BuscarPersonaID" name="BuscarPersonaName" insideNameButton="Buscar Persona" FontIconClass="search" />
                </div>
            </Contenedor>
        </div>
        );
    }


export default BusquedaMasiva;
