
const Contenedor = (props: any) =>{

 
    return (
        <div className={"classContenedor " + props.className}>
            <h4>{props.headerName}</h4>
            <div className="">
                <h6>{props.subHeaderName}</h6>
            </div>
            <div className="">
                {props.children}
            </div>
        </div>
    );
}

export default Contenedor;
