import  BreadCrumb  from '../../components/breadcrumb';
import  CardResultadoPersonaBusquedaMasiva  from '../../components/Personas/CardResultadoPersonaBusquedaMasiva';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';
import { useHistory } from 'react-router-dom';
import { BusquedaMasivaAction } from '../../redux/actions/BusquedaMasiva/BusquedaMasivaActions';
const ResultadoBusquedaMasiva = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    const {personasBusquedaMasiva} = useSelector((state: AppState) => state.personasBusquedaMasiva);

    const GoToListado = () => {
        history.push("/Home/BusquedaMasiva");
        dispatch(BusquedaMasivaAction([]));
    }

       return (
        <div className="ResultadoBusquedaPersona">
            {
                personasBusquedaMasiva.length > 0
                ? 
                <a onClick={GoToListado}><BreadCrumb link1="#" infoContenidoBreadcrumb="Nueva Busqueda"/></a>
                : 
                null      
            }
            <h4>REGISTROS ENCONTRADOS: {personasBusquedaMasiva.length} </h4>
            <div>
            {personasBusquedaMasiva.length > 0
                ? 
                personasBusquedaMasiva.map(item =>(
                    <CardResultadoPersonaBusquedaMasiva  
                        info={item}
                    />))
                : 
                <h4>CARGANDO...</h4>
            }
            </div>
        </div>
        );
    }

export default ResultadoBusquedaMasiva;
