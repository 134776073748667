import { GET_EMPRESA } from "../../actions/actionTypes";
import { EmpresasActionType, EmpresasStateType } from "../../actions/Empresas/empresasActions";

  const initialStateposts: EmpresasStateType = {
    Empresas: []
  };
  
const EmpresaReducer = (state = initialStateposts, action: EmpresasActionType): EmpresasStateType => {
    switch (action.type) {
      case GET_EMPRESA:
        return {
          ...state,
          Empresas: action.payload
        };
      default:
        return state;
    }
  }

  export default EmpresaReducer;