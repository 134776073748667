import BusquedaPersonas from './Personas/BusquedaPersonas';
import ResultadoBusquedaPersona from './Personas/ResultadoBusquedaPersona';
import DetallePersona from './Personas/DetallePersona';
import {
  Switch,
  Route
} from "react-router-dom";
import Layout from '../components/Layout';
import CrearUsuario from './Usuarios/CrearUsuario';
import ListaUsuarios from './Usuarios/ListaUsuarios';
import BusquedaVehiculo from './Vehiculo/BusquedaVehiculo';
import ResultadoBusquedaVehiculo from './Vehiculo/ResultadoBusquedaVehiculo';
import DetalleVehiculo from './Vehiculo/DetalleVehiculo';
import DetalleEmpresa from './Empresas/DetalleEmpresa';
import ResultadoBusquedaEmperesa from './Empresas/ResultadoBusquedaEmperesa';
import BusquedaEmpersa from './Empresas/BusquedaEmpersa';
import EditarUsuario from './Usuarios/EditarUsuario';
import BusquedaTelefono from './Telefono/BusquedaTelefono';
import DetalleTelefono from './Telefono/DetalleTelefono';
import BusquedaMasiva from './BusquedaMasiva/BusquedaMasiva';
import ResultadoBusquedaMasiva from './BusquedaMasiva/ResultadoBusquedaMasiva';


const Home = () =>{
    return (
      <div>
      <Layout>
        <Switch>
          {
            //Personas
          }
          <Route path="/Home/BusquedaPersonas" component={BusquedaPersonas}/>
          <Route path="/Home/ResultadoBusquedaPersona"component={ResultadoBusquedaPersona}/>
          <Route path="/Home/DetallePersona" component={DetallePersona}/>
          {
            //Vehiculos
          }
          <Route path="/Home/DetalleVehiculo" component={DetalleVehiculo}/>
          <Route path="/Home/ResultadoBusquedaVehiculo" component={ResultadoBusquedaVehiculo}/>
          <Route path="/Home/busquedaVehiculo" component={BusquedaVehiculo}/>
          {
            //Empresas
          }
          <Route path="/Home/DetalleEmpresa" component={DetalleEmpresa}/>
          <Route path="/Home/ResultadoBusquedaEmperesa" component={ResultadoBusquedaEmperesa}/>
          <Route path="/Home/BusquedaEmpersa" component={BusquedaEmpersa}/>
          {
            //Usuarios
          }
          <Route path="/Home/CrearUsuario" component={CrearUsuario}/>
          <Route path="/Home/EditarUsuario" component={EditarUsuario}/>
          <Route path="/Home/ListaUsuarios" component={ListaUsuarios}/>
          {
            //Telefono
          }
          <Route path="/Home/BusquedaTelefono" component={BusquedaTelefono}/>
          <Route path="/Home/DetalleTelefono" component={DetalleTelefono}/>
          {
            //Busqueda Masiva
          }
          <Route path="/Home/BusquedaMasiva" component={BusquedaMasiva}/>
          <Route path="/Home/ResultadoBusquedaMasiva" component={ResultadoBusquedaMasiva}/>
        </Switch>
    </Layout>
   </div>
    );
}
export default Home;