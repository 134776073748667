import axios from "../../base/axiosconfig";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import  useGetDetallePersona  from "../../hooks/Personas/useGetDetallePersona";
import { LoadingAction } from "../../redux/actions/Configuracion/LoadingActions";
import MiniLoading  from "../MiniLoading";
import swal from 'sweetalert'
import { PersonasAction } from "../../redux/actions/Personas/personasActions";
import { DetallePersonaAction } from "../../redux/actions/Personas/detallePersonaActions";
import { EmpresaDetalleAction, EmpresasAction } from "../../redux/actions/Empresas/empresasActions";
import { IEmpresa } from "../../redux/interfaces/Empresas/IEmpresa";

const CardInfoPersonasTelefonos = (props: any) => {

const {getDetallePersona} = useGetDetallePersona()
const [Persona, setPersona] = useState({
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
})

let Empresa : IEmpresa ={
  empresaId:-1,
  empresaIdentificacion :'',
  empresaNombre:'',
  empresaNombreComercial:'',
  actividadComercialNombre:'',
  fhIncorporacion:new Date ,
  fhCancelacion:new Date,
  tipoEmpresa:'',
  fhCreacion:new Date,
}

let history = useHistory();
const dispatch = useDispatch();

const [loading, setloading] = useState(true)

  useEffect(() => {
    dispatch(LoadingAction(false))
    setTimeout(() => {
      setloading(false)
    }, 1500);
  }, [props.persona.personaId])

  const SetPersonDetalle = () =>{
    dispatch(PersonasAction([]))
    dispatch(DetallePersonaAction(Persona))
    dispatch(EmpresasAction([]))
    dispatch(EmpresaDetalleAction(Empresa))
    dispatch(LoadingAction(true))
    getDetallePersona(props.persona.personaId)
    .then(() =>
      { 
        history.push("/Home/DetallePersona")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consulta Personas", error, "info")
        dispatch(LoadingAction(false))
      });
  }

  const GetinfoPersona = () =>{
    setloading(false)
    axios.get(`/api/Personas/DetallePersona/${props.persona.personaId}`)
    .then(function (response) {
      setPersona(response.data)
      setloading(false)
    })
    .catch(function (error) {
        console.log(error);
        setloading(false)
    })
  }

  const Loading = () =>{
    return (
      <div className="CardInfoPersonas">
          <MiniLoading/>
      </div>
    )
  }
  
  const Table = () =>{
    return (
    <div className="CardInfoPersonas" onClick={()=>SetPersonDetalle()}>
      <div>
        <img src={props.persona.fotoRuta} />
      </div>
      <div>
        <div>
          <h6>Nombre Persona</h6>
          <p>{props.persona.nombre }</p>
        </div>
        <div>
          <h6>Cédula</h6>
          <p>{props.persona.indetificacion}</p>
        </div>
        <div>
          <h6>Telefono</h6>
          <p>{props.persona.telefonoNumero }</p>
        </div>

        <div>
          <h6>Fecha</h6>
          <p>{props.persona.telefonoFhReferencia}</p>
        </div>
        <div>
          <h6>Nota</h6>
          <p>{props.persona.telefonoNota}</p>
        </div>
        <div>
          <h6>Tipo de telefono</h6>
          <p>{props.persona.telefonoTipoDescripcion}</p>
        </div>
        <div>
          <h6>Fuente</h6>
          <p>{props.persona.fuenteNombre}</p>
        </div>
        
      </div>
    </div>
    )
  }

  return (
    <div>
    {
      loading
      ?
      <div style={{display: 'flex',  justifyContent:'center'}}>
        <Loading/>
      </div>
      :<Table/>
    }
  </div>


  );
};

export default CardInfoPersonasTelefonos;
