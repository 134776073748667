import fileDownload from "js-file-download";
import { useEffect } from "react";
import Button  from "../components/Button";

const ReportView = (props: any) =>{
    useEffect(() => {
      if(window.orientation == 0){
        window.close();

        var binaryString = window.atob(props.reporte);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        fileDownload(bytes, 'Reporte.pdf');
        props.hide()
    }
    }, [props])

   return (
    <div id="contenedor-animacion-carga">
    <Button
      className="btn btn-primary btn-sm"
      OnClick={() => props.hide()}
      id="TraerTodosID"
      name="TraerTodosName"
      insideNameButton="Cerrar Reporte"
      FontIconClass="close"
    />
    <object
    data={`data:application/pdf;base64,${encodeURI(props.reporte)}`}
    type="application/pdf"
    width="100%"
    height="100%"
    >
    </object>
  </div>
   );
}

export default ReportView