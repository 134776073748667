import  BreadCrumb  from '../../components/breadcrumb';
import  CardResultadoPersona  from '../../components/Personas/CardResultadoPersona';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';

const ResultadoBusquedaPersona = () => {

    const {personas} = useSelector((state: AppState) => state.personas);
    let cantidadPersonas = personas.length

       return (
        <div className="ResultadoBusquedaPersona">
            <BreadCrumb link1="../Home/ResultadoBusquedaPersona" infoContenidoBreadcrumb="Resultados"/>
            <h4>REGISTROS ENCONTRADOS: {cantidadPersonas} </h4>
            <div>
            {personas.length > 0
                ? 
                personas.map(item =>(
                    <CardResultadoPersona  
                        info={item}
                    />))
                : 
                <h4>CARGANDO...</h4>
            }
            </div>
        </div>
        );
    }

export default ResultadoBusquedaPersona;
