import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingAction } from "../../redux/actions/Configuracion/LoadingActions";
import { VehiculoDetalleAction } from "../../redux/actions/Vehiculos/vehiculosActions";

const CardResultadoVehiculo = ({info}: any) => {
  let Vehiculos : any[] = []
  let history = useHistory();
  const dispatch = useDispatch();

const setPersonaDetalle = () =>{
  dispatch(LoadingAction(true))
    Vehiculos.push(info);
    dispatch(VehiculoDetalleAction(Vehiculos));
    history.push("/Home/DetalleVehiculo")
  }

  return (
    <div className="CardResultadoPersona" key={info.vehiculoId}>
      <div>
        <span className="material-icons">drive_eta</span>
        {/*<img src={info.fotoVehiculoSRC} />*/}
      </div>
      <div>
        <h6>
          {info.marcaDescripcion} | {info.modeloDescripcion}
        </h6>
        <h6>{info.colorDescripcion}</h6>
        <h6>{info.vehiculoAno}</h6>
        <h6>
          <a onClick={setPersonaDetalle}>Más Detalles</a>
        </h6>
      </div>
    </div>
  );
};

export default CardResultadoVehiculo;
