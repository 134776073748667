import swal from 'sweetalert'
import useGetDetallePersona from "../../hooks/Personas/useGetDetallePersona";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingAction } from "../../redux/actions/Configuracion/LoadingActions";

const CardResultadoPersonaBusquedaMasiva = ({info}: any) => {

 const {getDetallePersona} = useGetDetallePersona()

 const history = useHistory();
 const dispatch = useDispatch();
 
  function setPersonaDetalle(){
    dispatch(LoadingAction(true))
    getDetallePersona(info.personaId)
    .then(() =>
      { 
        history.push("/Home/DetallePersona")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consultas", error, "error")
        dispatch(LoadingAction(false))
      });
  }

  return (
    <div className="CardResultadoPersona">
      <div>
        <img src={info.fotoRuta} />
      </div>
      <div>
        <h6>{info.nombres}</h6>
        <h6>{info.apellido1} {info.apellido2}</h6>
        <h6>Tiene Casos:</h6>
        <p>{info.Casos  ? "Si" :"No"}</p>
        <h6>Tiene Fichas:</h6>
        <p>{info.Ficha ? "Si" :"No"}</p>
        <h6>
          <a onClick={()=> (setPersonaDetalle())}>Más Detalles</a>
        </h6>
      </div>
    </div>
  );
};

export default CardResultadoPersonaBusquedaMasiva;
