import { GET_PERSONAS} from '../../actions/actionTypes';
import { PersonasActionType, PersonasStateType } from '../../actions/Personas/personasActions';
  
  const initialStateposts: PersonasStateType = {
    personas: []
  };
  
const PersonasReducer = (state = initialStateposts, action: PersonasActionType): PersonasStateType => {
    switch (action.type) {
      case GET_PERSONAS:
        return {
          ...state,
          personas: action.payload
        };
      default:
        return state;
    }
  }

  export default PersonasReducer;