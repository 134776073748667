const CardResultadoDetalleVehiculo = ({info}: any,Personas:[]) => {
  return (
    <div>
    <div className="CardResultadoPersona">
      <div>
        <span className="material-icons">drive_eta</span>
        {/*<img src={info.fotoVehiculoSRC} />*/}
      </div>
    <div>
      <div>
          <h6>Marca       |     Modelo</h6>
          <p>{info.marcaDescripcion} | {info.modeloDescripcion}</p>
        </div>
        <div>
          <h6>Placa</h6>
          <p>{info.vehiculoPlaca}</p>
        </div>
        <div>
          <h6>Chasis</h6>
          <p>{info.vehiculoChasis}</p>
        </div>
        <div>
          <h6>Color</h6>
          <p>{info.colorDescripcion}</p>
        </div>
        <div>
          <h6>Año</h6>
          <p>{info.vehiculoAno}</p>
        </div>
        <div>
          <h6>Fecha Creacion</h6>
          <p>{info.vehiculoFechaCreacion.split('T')[0]} {info.vehiculoFechaCreacion.split('T')[1]}</p>
        </div>
    </div>
  </div>
</div>
  );
};

export default CardResultadoDetalleVehiculo;
