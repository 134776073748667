import axios from "../../base/axiosconfig";
import { useDispatch } from "react-redux";
import { PersonaTelefonosAction } from "../../redux/actions/Telefonos/PersonaTelefonosActions";

const useGetPersonasByTelefono = () =>{
    const dispatch = useDispatch();
    const getPersonasByTelefono = 
    (
      numero:any,
      ) => {
        return new Promise<any>((resolve, reject) => {
          axios.get(`/api/Busqueda/BusquedaTelefono/${numero}`,)
            .then(function (response) {
              if(response.data === "Consultas Agotadas"){
                dispatch(PersonaTelefonosAction([]));
                reject(response.data)
              }else if(response.data.length == 0) {
                dispatch(PersonaTelefonosAction([]));
                reject("No se encontro ninguna persona")
              }else{
                dispatch(PersonaTelefonosAction(response.data));
                resolve(()=>null)
              }
            })
            .catch(function (error) {
                reject(error)
            })
          })
        }
  return{
    getPersonasByTelefono
  }
}

export default useGetPersonasByTelefono;