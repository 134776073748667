import { IVehiculos } from "../../interfaces/Vehiculos/IVehiculos";
import {GET_VEHICULOS, GET_VEHICULO_DETALLE } from "../actionTypes";

export interface VehiculoDetalleStateType {
    DetalleVehiculo: IVehiculos[];
}

export interface VehiculoDetalleActionType {
  type: typeof GET_VEHICULO_DETALLE;
  payload: IVehiculos[];
}

export interface VehiculosStateType {
    Vehiculos: IVehiculos[];
}

export interface VehiculosActionType {
  type: typeof GET_VEHICULOS;
  payload: IVehiculos[];
}

export const VehiculoDetalleAction = 
(DetalleVehiculo: IVehiculos[]): VehiculoDetalleActionType => {
  return {
    type: GET_VEHICULO_DETALLE,
    payload: DetalleVehiculo
  };
};

export const VehiculosAction = (Vehiculos: IVehiculos[]): any => {
    return {
      type: GET_VEHICULOS,
      payload: Vehiculos
    };
  };