import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingAction } from "../../redux/actions/Configuracion/LoadingActions";
import { EmpresaDetalleAction } from "../../redux/actions/Empresas/empresasActions";

const CardResultadoEmpresas = ({info}: any) => {

  let history = useHistory();
  const dispatch = useDispatch();

const setEmpresaDetalle = () =>{
    dispatch(LoadingAction(true));
    dispatch(EmpresaDetalleAction(info));
    history.push("/Home/DetalleEmpresa")
  }

  return (
    <div className="CardResultadoPersona" key={info.empresaId}>
      <div>
        <span className="material-icons">apartment</span>
      </div>
      <div>
        <h6>
          {info.empresaNombre}
        </h6>
        <h6>{info.actividadComercialNombre}</h6>
        <h6>{info.fhIncorporacion.toString().substring(0,10)}</h6>
        <h6>
          <a onClick={setEmpresaDetalle}>Más Detalles</a>
        </h6>
      </div>
    </div>
  );
};

export default CardResultadoEmpresas;
