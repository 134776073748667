import axios from "../../base/axiosconfig";
import { useDispatch } from "react-redux";
import { UsuariosAction } from "../../redux/actions/Usuarios/UsuariosAction";

const useGetUsuarios = () =>{
    const dispatch = useDispatch();
    const getUsuarios = () => {
        return new Promise<any>((resolve, reject) => {
          axios.get(`/api/usuarios/Get-Usuarios`,)
            .then(function (response) {
                dispatch(UsuariosAction(response.data));
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            })
          })
        }
  return{
    getUsuarios
  }
}

export default useGetUsuarios;