import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import  BreadCrumb  from '../../components/breadcrumb';
import CardResultadoDetalleEmpresa from '../../components/Empresas/CardResultadoDetalleEmpresa';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { AppState } from '../../redux/reducers/rootReducer';

const DetalleEmpresa = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    
    const {Empresas} = useSelector((state: AppState) => state.empresas);
    const {DetallePersona} = useSelector((state: AppState) => state.detallePersona);

    const GoListado = () =>{
        history.push("/Home/ResultadoBusquedaEmperesa");
    }
    const GoDetallePersona = () =>{
        history.push("/Home/DetallePersona");
        dispatch(LoadingAction(true))
    }
       return (
        <div className="DetalleVehiculo">
                                                {
                Empresas.length > 0
                ? 
                <a onClick={()=>GoListado()}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar a los Resultados"/></a>
                : 
                null      
            }
                                    {
                DetallePersona.personaId != -1
                ? 
                <a onClick={()=>GoDetallePersona()}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar al detalle Persona"/></a>
                : 
                null      
            }

         <h4>REGISTROS ENCONTRADOS</h4>
            <div>
                <div>
                <CardResultadoDetalleEmpresa/>
                </div>
            </div>
    </div>
    );
}



export default DetalleEmpresa;
