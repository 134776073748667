import React, { Component } from 'react';
import { LinkComponent } from '../components/link';


export class BreadCrumb extends Component {

    props = {
        link1:'',
        infoContenidoBreadcrumb:''
    }

    render() {
        return (
            <div className="Breadcrumb">
                <span class="material-icons">home</span> <LinkComponent href="../home" infoContenido="Home" id="HomeLinkID" name="HomeLinkName" /> / <LinkComponent href={this.props.link1} infoContenido={this.props.infoContenidoBreadcrumb} id="busquedaPersonasLinkID" name="busquedaPersonasLinkName" /> 
            </div>
        );
    }
}

export default BreadCrumb;
