import { GET_PERSONA_DETALLE } from "../../actions/actionTypes";
import { DetallePersonaActionType, DetallePersonaStateType } from "../../actions/Personas/detallePersonaActions";

  const initialState: DetallePersonaStateType = {
    DetallePersona: {
        personaId: -1,
        documento:'',
        nombres:'',
        apellido1:'',
        apellido2:'',
        nombrecompleto:'',
        fhNacimiento:new Date,
        edad:-1,
        sexo:'',
        genero:'',
        fhCreacion:new Date,
        lugarNacimiento:'',
        nacionalidadDescripcion: '',
        estatusDescripcion:'',
        fotoRuta:'',
        correos:[],
        direcciones:[],
        telefonosPersona:[],
        vehiculos:[]
    }
  };
  
const PersonasReducer = (
  state = initialState,
   action: DetallePersonaActionType)
   : DetallePersonaStateType => {
    switch (action.type) {
      case GET_PERSONA_DETALLE:
        return {
          ...state,
          DetallePersona: action.payload
        };
      default:
        return state;
    }
  }

  export default PersonasReducer;