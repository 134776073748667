import { useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import  Contenedor  from '../../components/Contenedor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DetallePersonaAction } from '../../redux/actions/Personas/detallePersonaActions';
import { IDetallePersona } from '../../redux/interfaces/Personas/IDetallePersona';
import { PersonasAction } from '../../redux/actions/Personas/personasActions';
import { IVehiculos } from '../../redux/interfaces/Vehiculos/IVehiculos';
import { VehiculoDetalleAction, VehiculosAction } from '../../redux/actions/Vehiculos/vehiculosActions';
import swal from 'sweetalert'
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import useGetPersonasByTelefono from '../../hooks/Personas/useGetPersonasByTelefono';
import { PersonaTelefonosAction } from '../../redux/actions/Telefonos/PersonaTelefonosActions';

let Persona : IDetallePersona = {
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
}

const  BusquedaTelefono = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [Telefono,SetTelefono] = useState('')

    const {getPersonasByTelefono} = useGetPersonasByTelefono()

    const HundlerInput = (event:any) => {
        switch (event.name) {
          case "Telefono":
            SetTelefono(event.value);
            break;
        }
      };



    const BuscarTelefono = () => {
      dispatch(PersonasAction([]))
      dispatch(DetallePersonaAction(Persona))
      dispatch(VehiculosAction([]))
      dispatch(VehiculoDetalleAction([]))
      dispatch(PersonaTelefonosAction([]))
      
        if (Telefono != ''){
          dispatch(LoadingAction(true));
          getPersonasByTelefono(Telefono)
          .then(()=> 
          {
            history.push("/Home/DetalleTelefono")
            dispatch(LoadingAction(false))
          })
          .catch(error=>
          { 
            dispatch(LoadingAction(false))
            swal("Busqueda telefono", error, "info")
          });
            
        }else{
            swal("Busqueda telefono", "Debe ingresar el telefono", "info");
        }
    }    


    return (
        <div className="BuscarPersonas">
            <BreadCrumb link1="../Home/BusquedaTelefono" infoContenidoBreadcrumb="Busqueda por telefono"/>
            <Contenedor className="" headerName="Criterios de búsqueda" subHeaderName="FAVOR LLENAR LOS CRITERIOS DE BúSQUEDA">
                <div className="class-contenedor">
                    <Input OnChange={HundlerInput} label="" type="text" id="Telefono" name="Telefono" placeholder="Telefono" maxLength="30"/>
                        <Button OnClick={BuscarTelefono} id="BuscarEmpresaID" name="BuscarEmpresa" insideNameButton="Buscar telefono" FontIconClass="search" />
                </div>
            </Contenedor>
        </div>
        );
}

export default BusquedaTelefono;
