import { IDetallePersona } from "../../interfaces/Personas/IDetallePersona";
import { GET_PERSONA_DETALLE } from "../actionTypes";

export interface DetallePersonaStateType {
    DetallePersona: IDetallePersona;
}

export interface DetallePersonaActionType {
    type: typeof GET_PERSONA_DETALLE;
    payload: IDetallePersona;
  }

export const DetallePersonaAction = 
(DetallePersona: IDetallePersona): DetallePersonaActionType => {
    return {
      type: GET_PERSONA_DETALLE,
      payload: DetallePersona
    };
  };