
import spinner from '../image/spinner.gif'


const Loading = () =>{

   return (
     <div id="contenedor-animacion-carga">
            <img id="animacion-carga" src={spinner} />
     </div>
   );
}

export default Loading