import { useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import  Contenedor  from '../../components/Contenedor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DetallePersonaAction } from '../../redux/actions/Personas/detallePersonaActions';
import { IDetallePersona } from '../../redux/interfaces/Personas/IDetallePersona';
import { PersonasAction } from '../../redux/actions/Personas/personasActions';
import { VehiculoDetalleAction, VehiculosAction } from '../../redux/actions/Vehiculos/vehiculosActions';
import swal from 'sweetalert'
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import useGetEmpresaByRNC from '../../hooks/Empresas/useGetEmpresaByRNC';
import useGetEmpresaByNombre from '../../hooks/Empresas/useGetEmpresaByNombre';


let Persona : IDetallePersona = {
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
}

const  BusquedaEmpersa = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [RNC,SetRNC] = useState('')
    const [Descripción,SetDescripción] = useState('')

    const {getEmpresaByNombre} = useGetEmpresaByNombre()
    const {getEmpresaByRNC} = useGetEmpresaByRNC()

    const HundlerInput = (event:any) => {
        switch (event.name) {
          case "RNC":
            SetRNC(event.value);
            break;
            case "Descripción":
              SetDescripción(event.value);
            break;
        }
      };



    const BuscarEmpresa = () => {
      dispatch(PersonasAction([]))
      dispatch(DetallePersonaAction(Persona))
      dispatch(VehiculosAction([]))
      dispatch(VehiculoDetalleAction([]))
      
        if (RNC != '' ){
          dispatch(LoadingAction(true));
          getEmpresaByRNC(RNC)
          .then(()=> 
          {
            history.push("/Home/DetalleEmpresa")
            dispatch(LoadingAction(false))
          })
          .catch(error=>
          { 
            dispatch(LoadingAction(false))
            swal("Busqueda Empresa", error, "info")
          });
            
        }else{
          if(Descripción === '') {
            swal("Busqueda Empresa", "Debe ingresar el RNC o la Descripción", "info");
          }else{
            dispatch(LoadingAction(true));
            getEmpresaByNombre(Descripción)          
            .then(()=> 
            {
              history.push("/Home/ResultadoBusquedaEmperesa");
              dispatch(LoadingAction(false))
            })
            .catch(error=>
            {  
              dispatch(LoadingAction(false))
              swal("Busqueda Empresa", error, "info")
            });
          }
        }
    }    


    return (
        <div className="BuscarPersonas">
            <BreadCrumb link1="../Home/BusquedaEmpersa" infoContenidoBreadcrumb="Busqueda Empresas"/>
            <Contenedor className="" headerName="Criterios de búsqueda" subHeaderName="FAVOR LLENAR LOS CRITERIOS DE BúSQUEDA">
                <div className="class-contenedor">
                     {/*<Input OnChange={HundlerInput} label="" type="text" id="NombrePropietario" name="NombrePropietario" placeholder="Nombre Propietario/a" />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="RNC" name="RNC" placeholder="RNC" maxLength="30"/>
                    <Input OnChange={HundlerInput} label="" type="text" id="Descripción" name="Descripción" placeholder="Descripción" maxLength="30"/>
                    <div>
                        <Button OnClick={BuscarEmpresa} id="BuscarEmpresaID" name="BuscarEmpresa" insideNameButton="Buscar Empresa" FontIconClass="search" />
                    </div>
                </div>
            </Contenedor>
        </div>
        );
}

export default BusquedaEmpersa;
