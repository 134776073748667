import { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import { setTimeout } from 'timers';
import  MiniLoading  from "../components/MiniLoading";

const TableComponent =  (props: { tabsInfo: any[]; tabsPanel: any[]; namelist: any[]; OnChange: any;}) => {

    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (props.tabsPanel.length > 0 ) {
            setloading(false)
        }
    }, [props.tabsPanel])

    setTimeout(() => {
        setloading(false)
    }, 10000);

        return (
            <div className="TableComponent">
            {
                loading 
                ?  
                <div style={{display: 'flex',  justifyContent:'center'}}>
                    <MiniLoading/>
                </div>

                :
                props.tabsPanel.length > 0 
                    ?   
                        <table>
                            <thead>
                                <tr>
                                    {props.tabsInfo.map(info =>  (
                                    <th>{info.nombre}</th>
                                    ))}  
                                </tr>
                            </thead>
                            <tbody>
                                {props.tabsPanel.map((info)=>(
                                    <tr onClick={()=>props.OnChange(info)}>
                                        {props.namelist.map((item) =>(
                                            item ==='fotoUrl'
                                            ?  
                                                <td><img src={info[item]} style={{width: '50px',  height:'50px'}} /></td>
                                            :
                                                <td>{info[item]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                :
                <p>No existe registros asociados</p>
            }
            </div>
        );
    }

export default TableComponent;
