import { useState } from 'react';

const Select = (props: { OnChange: any; id: string; name: string; Placeholder : string; options:any[]; disabled:any}) => {

  const [Value,setValue] = useState()

  const handleChange = (event: any) => {
    
    props.OnChange(event.target);
    setValue(event.target.value)
    }

    return (
            <select id={props.id} name={props.name} disabled={props.disabled} placeholder={props.Placeholder} value={Value} onChange={handleChange}>
              <option value={-1}>Seleccione</option>
              {props.options.map((item)=>(
                <option value={item[props.id]}>{item[props.name]}</option>
              ))}
              </select>
    );
}

export default Select;