import axios from "../../base/axiosconfig";
import { useDispatch } from "react-redux";
import { PersonasAction } from "../../redux/actions/Personas/personasActions";

const useGetPersonas = () =>{
    const dispatch = useDispatch();
    const getPersonas = 
    (
        TipoDocumento:any,
        Documento:any,
        Nombres:any,
        Apellido1:any,
        Apellido2:any,
        Sexo:any,
        EdadInicio:any,
        EdadFin:any,
        LugarNacimiento:any
      ) => {
        return new Promise<any>((resolve, reject) => {
          axios.post(`/api/Personas/Buscar-Personas`, {
            TipoDocumento,
              Documento,
              Nombres,
              Apellido1,
              Apellido2,
              Sexo,
              EdadInicio,
              EdadFin,
              LugarNacimiento
            },)
            .then(function (response) {
              if(response.data === "Consultas Agotadas"){
                dispatch(PersonasAction([]));
                reject(response.data)
              }else if(response.data.length == 0) {
                dispatch(PersonasAction([]));
                reject("No se encontro ninguna persona")
              }else{
                dispatch(PersonasAction(response.data));
                resolve(()=>null)
              }
            })
            .catch(function (error) {
                reject(error)
            })
          })
        }
  return{
    getPersonas
  }
}

export default useGetPersonas;