import { GET_VEHICULO_DETALLE } from "../../actions/actionTypes";
import { VehiculoDetalleActionType, VehiculoDetalleStateType } from "../../actions/Vehiculos/vehiculosActions";
import { IVehiculos } from "../../interfaces/Vehiculos/IVehiculos";
  
interface InitialState {
  DetalleVehiculo: IVehiculos[];
  }
  
  const initialState: InitialState = {
    DetalleVehiculo: []
  };

const VehiculosDetalleReducer = (
  state: InitialState = initialState, action: VehiculoDetalleActionType)
    : VehiculoDetalleStateType => {
    switch (action.type) {
      case GET_VEHICULO_DETALLE:
        return {
          ...state,
          DetalleVehiculo: action.payload
        };
      default:
        return state;
    }
}

export default VehiculosDetalleReducer;