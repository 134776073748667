import { IUsuarios } from "../../interfaces/Usuarios/IUsuarios";
import { GET_ALL_USUARIOS } from "../actionTypes";

export interface UsuariosStateType {
    Usuarios: IUsuarios[];
}

export interface UsuariosActionType {
  type: typeof GET_ALL_USUARIOS;
  payload: IUsuarios[];
}

export const UsuariosAction = (Usuarios: IUsuarios[]): UsuariosActionType => {
  return {
    type: GET_ALL_USUARIOS,
    payload: Usuarios
  };
};