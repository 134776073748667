import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";

const CardInfoPersonas = () => {
  const {DetallePersona} = useSelector((state: AppState) => state.detallePersona);

  return (
    <div className="CardInfoPersonas">
      <div>
        <img src={DetallePersona.fotoRuta} />
      </div>
      <div>
        <div>
          <h6>Nombres</h6>
          <p>{DetallePersona.nombres}</p>
        </div>
        <div>
          <h6>Apellidos</h6>
          <p>{DetallePersona.apellido1} {DetallePersona.apellido2}</p>
        </div>
       <div>
          <h6>Cédula</h6>
          <p>{DetallePersona.documento}</p>
        </div>
        <div>
          <h6>Edad</h6>
          <p>{DetallePersona.edad} años</p>
        </div>
        <div>
          <h6>Fecha nacimiento</h6>
          <p>{DetallePersona.fhNacimiento.toString().split('T')[0]} {DetallePersona.fhNacimiento.toString().split('T')[1]}</p>
        </div>
        <div>
          <h6>Genero</h6>
          <p>{DetallePersona.genero}</p>
        </div>
        <div>
          <h6>Lugar de nacimiento</h6>
          <p>{DetallePersona.lugarNacimiento}</p>
        </div>
      </div>
    </div>
  );
};

export default CardInfoPersonas;
