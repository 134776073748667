import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import axios from '../../base/axiosconfig';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { useDispatch, useSelector } from 'react-redux';
import useGetDetallePersona from '../../hooks/Personas/useGetDetallePersona';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert'
import useGetDetalleVehiculo from '../../hooks/Vehiculos/useGetDetalleVehiculo';
import { VehiculoDetalleAction, VehiculosAction } from '../../redux/actions/Vehiculos/vehiculosActions';
import { PersonasAction } from '../../redux/actions/Personas/personasActions';
import { DetallePersonaAction } from '../../redux/actions/Personas/detallePersonaActions';
import { IDetallePersona } from '../../redux/interfaces/Personas/IDetallePersona';
import TableComponent from '../../components/Table';
import { AppState } from '../../redux/reducers/rootReducer';

let Persona : IDetallePersona = {
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
}


const CardResultadoDetalleEmpresa = () => {

  const {DetalleEmpresas} = useSelector((state: AppState) => state.EmpresaDetalle);

  const [Vehiculos, setVehiculos] = useState<any[]>([])
  const [Telefonos, setTelefonos] = useState([])
  const [Direcciones, setDirecciones] = useState([])
  const [Socios, setSocios] = useState([])
  const [Bienes, setBienes] = useState([])


  const {getDetallePersona} = useGetDetallePersona()
  const {getDetalleVehiculo} = useGetDetalleVehiculo()
  
  const history = useHistory();   
  const dispatch = useDispatch();
  
  useEffect(() => {
      dispatch(LoadingAction(false));
      GetVehiculos()
      GetTelefonos()
      GetDirecciones()
      GetSocios()
      GetBienes()
  }, [DetalleEmpresas])

  const GetVehiculos = () =>{
    axios.get(`/api/Empresas/GetVehiculos/${DetalleEmpresas.empresaId}`)
    .then(function (response) {
      setVehiculos(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }
  const GetTelefonos = () =>{
    axios.get(`/api/Empresas/GetTelefonos/${DetalleEmpresas.empresaId}`)
    .then(function (response) {
      setTelefonos(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }
  const GetDirecciones = () =>{
    axios.get(`/api/Empresas/GetDirecciones/${DetalleEmpresas.empresaId}`)
    .then(function (response) {
      setDirecciones(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }
  const GetSocios = () =>{
    axios.get(`/api/Empresas/GetSocios/${DetalleEmpresas.empresaId}`)
    .then(function (response) {
      setSocios(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })

  }

  const GetBienes = () =>{
    axios.get(`/api/Busqueda/GetBienesByEmpresa/${DetalleEmpresas.empresaId}`)
    .then(function (response) {
      setBienes(response.data)
    })
    .catch(function (error) {
        console.log(error);
    })
  }

  const SetPersonDetalle = (persona:any) =>{
    dispatch(VehiculosAction([]))
    dispatch(VehiculoDetalleAction([]))
    dispatch(LoadingAction(true))
    getDetallePersona(persona.personaId)
    .then(() =>
      { 
        history.push("/Home/DetallePersona")
        dispatch(LoadingAction(false))
      }
      ).catch(error => {
        swal("Consulta Personas", error, "info")
        dispatch(LoadingAction(false))
      });
  }

  const SetVehiculoDetalle = (vehiculo:any) =>{
    dispatch(PersonasAction([]))
    dispatch(DetallePersonaAction(Persona))
    dispatch(LoadingAction(true))
    getDetalleVehiculo(vehiculo.vehiculoPlaca,vehiculo.vehiculoChasis,-1)
    .then(()=> 
    {
      history.push("/Home/DetalleVehiculo");
      dispatch(LoadingAction(false))
    })
    .catch(error=>
    {  
      dispatch(LoadingAction(false))
      swal("Busqueda Vehiculo", error, "info")
    });
  }


  return (
    <div>
    <div className="CardResultadoPersona">
      <div>
        <span className="material-icons">apartment</span>
      </div>
    <div>
    
    <div>
          <h6>RNC</h6>
          <p>{DetalleEmpresas.empresaIdentificacion}</p>
        </div>
      <div>
          <h6>Nombre</h6>
          <p>{DetalleEmpresas.empresaNombre}</p>
        </div>
        <div>
          <h6>Nombre Comercial</h6>
          <p>{DetalleEmpresas.empresaNombreComercial}</p>
        </div>
        <div>
          <h6>Actividad Comercial</h6>
          <p>{DetalleEmpresas.actividadComercialNombre}</p>
        </div>
        <div>
          <h6>Fecha de Incorporacion</h6>
          <p>{DetalleEmpresas.fhIncorporacion}</p>
        </div>
        <div>
          <h6>Tipo de Empresa</h6>
          <p>{DetalleEmpresas.tipoEmpresa}</p>
        </div>
    </div>
  </div>
  {/*
      <div>
      <h4>PROPIETARIO/A/</h4>
          <CardInfoPersonasEmpresa id={info.personaId}/>
      </div>
  */}

  <div>
  <div className="TableComponent">
            <Collapsible trigger="Socios" open>

            <TableComponent 
                tabsInfo={[
                  {nombre:'Foto'},
                        {nombre:'Accionista Nombre'},
                        {nombre:'Tipo Relacion'},
                        {nombre:'Numero Acciones'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={Socios} 
                namelist={[
                  'fotoUrl',
                    'accionistaNombre',
                    'tipoRelacionDescripcion',
                    'numeroAcciones',
                    'fechaRegistro'
                    ]} 
                OnChange={SetPersonDetalle}></TableComponent>
            </Collapsible>
            <Collapsible trigger="Bienes" open>
                <TableComponent 
                tabsInfo={[
                      {nombre:'Nombre'},
                      {nombre:'Valor'},
                      {nombre:'Direccion'},
                      {nombre:'Municipio'},
                      {nombre:'Provincia'},
                      {nombre:'Tipo'},
                      {nombre:'Fecha creacion'},       
                      {nombre:'Fecha'}
                     ]} 
                tabsPanel={Bienes} 
                namelist={[
                 'bienComercial',
                  'bienValor',
                  'bienDireccion',
                  'bienMunicipio',
                  'bienProvincia',
                  'bienTipo',
                  'bienCreacion',
                  'bienFecha',
                    ]} 
                OnChange={()=> null}></TableComponent>
            </Collapsible>
            <Collapsible trigger="Vehiculos" open>
                <TableComponent 
                tabsInfo={[
                      {nombre:'Marca'},
                      {nombre:'Modelo'},
                      {nombre:'Placa'},
                      {nombre:'Chasis'},
                      {nombre:'Color'},
                      {nombre:'Año'},
                      {nombre:'Fecha relación'}           
                ]} 
                tabsPanel={Vehiculos} 
                namelist={[
                  'marcaDescripcion',
                  'modeloDescripcion',
                  'vehiculoPlaca',
                  'vehiculoChasis',
                  'colorDescripcion',
                  'vehiculoAno',
                  'vehiculoFechaCreacion'
                    ]} 
                OnChange={SetVehiculoDetalle}></TableComponent>
            </Collapsible>
            <Collapsible trigger="Teléfonos" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Numero'},
                        {nombre:'Tipo'},
                        {nombre:'Fuente'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={Telefonos} 
                namelist={[
                    'telefonoNumero',
                    'telefonoTipoDescripcion',
                    'fuenteNombre',
                    'telefonoFhReferencia'
                    ]} 
                OnChange={()=> null}></TableComponent>
            </Collapsible>
            <Collapsible trigger="Direcciones" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'Pais'},
                        {nombre:'Ciudad'},
                        {nombre:'Provincia'},
                        {nombre:'Fuente'},
                        {nombre:'Dirrecion'},
                        {nombre:'Municipio'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={Direcciones} 
                namelist={[
                    'paisNombre',
                    'ciudadNombre',
                    'provinciaNombre',
                    'fuenteDato',
                    'direccionDescripcion',
                    'municipioNombre',
                    'fhVinculacion'
                    ]} 
                OnChange={()=> null}></TableComponent>
            </Collapsible>
            </div>
        </div>
      </div>
  );
};

export default CardResultadoDetalleEmpresa;
