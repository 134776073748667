import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import  BreadCrumb  from '../../components/breadcrumb';
import CardResultadoEmpresas from '../../components/Empresas/CardResultadoEmpresas';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { AppState } from '../../redux/reducers/rootReducer';

const ResultadoBusquedaEmperesa = () => {

    const dispatch = useDispatch();
    const {Empresas} = useSelector((state: AppState) => state.empresas);

    useEffect(() => {
        if (Empresas.length > 0) {
            dispatch(LoadingAction(false))
        }

    }, [Empresas])

       return (
        <div className="ResultadoBusquedaPersona">
            <BreadCrumb link1="../Home/ResultadoBusquedaEmperesa" infoContenidoBreadcrumb="Resultado Busqueda Empresa"/>
            <h4>REGISTROS ENCONTRADOS: {Empresas.length}</h4>
            <div>
            {Empresas.length > 0

                ? 
                Empresas.map(item =>(
                    <CardResultadoEmpresas  
                        info={item}
                    />))
                : 
                <h4>CARGANDO...</h4>
            }
            </div>
        </div>
        );
    }



export default ResultadoBusquedaEmperesa;
