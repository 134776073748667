import { useEffect, useState } from 'react';
import  BreadCrumb  from '../../components/breadcrumb';
import TabsComponent from '../../components/Tabs';
import CardInfoPersonas from '../../components/Personas/cardInfoPersonas';
import Collapsible from 'react-collapsible';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';
import axios from '../../base/axiosconfig';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import useGetEmpresaByRNC from '../../hooks/Empresas/useGetEmpresaByRNC';
import swal from 'sweetalert'
import { VehiculoDetalleAction, VehiculosAction } from '../../redux/actions/Vehiculos/vehiculosActions';
import { EmpresaDetalleAction, EmpresasAction } from '../../redux/actions/Empresas/empresasActions';
import useGetDetalleVehiculo from '../../hooks/Vehiculos/useGetDetalleVehiculo';
import useGetDetallePersona from '../../hooks/Personas/useGetDetallePersona';
import { PersonasAction } from '../../redux/actions/Personas/personasActions';
import TableComponent from '../../components/Table';
import { IEmpresa } from '../../redux/interfaces/Empresas/IEmpresa';
import Button  from "../../components/Button";
import ReportView  from "../../components/ReportView";

  
let Empresa : IEmpresa ={
    empresaId:-1,
    empresaIdentificacion :'',
    empresaNombre:'',
    empresaNombreComercial:'',
    actividadComercialNombre:'',
    fhIncorporacion:new Date ,
    fhCancelacion:new Date,
    tipoEmpresa:'',
    fhCreacion:new Date,
  }

const DetallePersona = () => {

    const {DetallePersona} : any = useSelector((state: AppState) => state.detallePersona);
    const {personas} = useSelector((state: AppState) => state.personas);

    const [Fichas, setFichas] = useState([])
    const [Empresas, setEmpresas] = useState([])
    const [Bienes, setBienes] = useState([])
    const [Casos, setCasos] = useState([])
    const [Relacionados, setRelacionados] = useState([])
    const [FotosPersona, setFotosPersona] = useState<any[]>([])
    const [HistorialLaboral, setHistorialLaboral] = useState([])

    const [Reporte, setreporte] = useState("")
    const [Visible, setvisible] = useState(false)

    const {getDetalleVehiculo} = useGetDetalleVehiculo()
    const {getEmpresaByRNC} = useGetEmpresaByRNC()
    const {getDetallePersona} = useGetDetallePersona()

    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoadingAction(false))
        if (DetallePersona.personaId != -1) {
            getFichas()
            getEmpresa()
            getBienes()
            getCasos()
            getRelacionados()
            getHistorialLaboral()
            getFotosPersona()
        }

    }, [DetallePersona.personaId])

  const getFichas = () => {
    axios.get(`/api/PN/GetDenuncias/${DetallePersona.personaId}`,)
      .then(function (response) {
        setFichas(response.data);
      })
      .catch(function (error) {
          console.log(error)
      });
    }

    const getEmpresa = () => {
        axios.get(`/api/Busqueda/GetAccionistasByPersona/${DetallePersona.personaId}`,)
        .then(function (response) {
            setEmpresas(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }
    const getBienes = () => {
        axios.get(`/api/Busqueda/GetBienesByPersona/${DetallePersona.personaId}`,)
        .then(function (response) {
            setBienes(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const getCasos = () => {
        axios.get(`/api/Busqueda/GetCasos/${DetallePersona.personaId}`,)
        .then(function (response) {
            setCasos(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const getRelacionados = () => {
        axios.get(`/api/Busqueda/GetRelacionados/${DetallePersona.personaId}`,)
        .then(function (response) {
            setRelacionados(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }
    const getHistorialLaboral = () => {
        axios.get(`/api/Busqueda/GetHistorialLaboral/${DetallePersona.personaId}`,)
        .then(function (response) {
            setHistorialLaboral(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const getFotosPersona = () => {
        axios.get(`/api/Personas/Fotos-Personas/${DetallePersona.personaId}`,)
        .then(function (response) {
            setFotosPersona(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
    }
    
    const GoToListado = () => {
        history.push("/Home/ResultadoBusquedaPersona");
    }


    const SetEmpresaDetalle = (Empresa:any) =>{
        dispatch(LoadingAction(true))
        dispatch(PersonasAction([]))
        dispatch(EmpresasAction([]))
        dispatch(EmpresaDetalleAction(Empresa))
        dispatch(VehiculosAction([]))
        dispatch(VehiculoDetalleAction([]))
        getEmpresaByRNC(Empresa.empresaIdentificacion)
        .then(()=> 
        {   
          history.push("/Home/DetalleEmpresa")
          dispatch(LoadingAction(false))
        })
        .catch(error=>
        { 
          dispatch(LoadingAction(false))
          swal("Busqueda Empresa", error, "info")
        });
      }

      const SetVehiculoDetalle = (vehiculo:any) =>{
        dispatch(EmpresasAction([]))
        dispatch(EmpresaDetalleAction(Empresa))
        dispatch(LoadingAction(true))
        getDetalleVehiculo(vehiculo.vehiculoPlaca,vehiculo.vehiculoChasis,-1)
        .then(()=> 
        {
          history.push("/Home/DetalleVehiculo");
          dispatch(LoadingAction(false))
        })
        .catch(error=>
        {  
          dispatch(LoadingAction(false))
          swal("Busqueda Vehiculo", error, "info")
        });
      }

      const SetPersonDetalle = (Persona:any) =>{
        dispatch(VehiculosAction([]))
        dispatch(VehiculoDetalleAction([]))
        dispatch(EmpresasAction([]))
        dispatch(EmpresaDetalleAction(Empresa))
        dispatch(LoadingAction(true))
        getDetallePersona(Persona.personaId_Destino)
        .then(() =>
          {  
            history.push("/Home/DetallePersona")
            dispatch(LoadingAction(false))
          }
          ).catch(error => {
            swal("Consulta Personas", error, "info")
            dispatch(LoadingAction(false))
          });
      }
      const getReportePersona = () =>{
        dispatch(LoadingAction(true))
        axios.get(`/api/Reportes/GetReporte/${DetallePersona.personaId}`,)
        .then(function (response) {
            setreporte(response.data)
            dispatch(LoadingAction(false))
            setvisible(true)
        })
        .catch(function (error) {
            dispatch(LoadingAction(false))
            console.log(error)
        });

      }

      const hidereport = () =>{
        setvisible(false)
        setreporte("")
      }
      
      const tabsInfo = [
        {id:1,nombre:'Personales'},
        {id:2,nombre:'Vinculaciones'},
        {id:3,nombre:'Fichas'},
        {id:4,nombre:'Fotos'},
        {id:5,nombre:'Colaboraciones'}
    ]

    const tabsPanel =[
        {id:1,nombre:
        <div>
            <CardInfoPersonas />
            <Button
                OnClick={() => getReportePersona()}
                id="TraerTodosID"
                name="TraerTodosName"
                insideNameButton="Imprimir Reporte"
                FontIconClass="done"
            />
            <div className="TableComponent">
            <Collapsible trigger=" Direcciones" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Ciudad'},
                        {nombre:'Sector'},
                        {nombre:'Direccion'},
                        {nombre:'Fuente'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={DetallePersona.direcciones} 
                namelist={[
                    'ciudadNombre',
                    'sectorNombre',
                    'direccionDescripcion',
                    'fuenteDato',
                    'fhVinculacion'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            <Collapsible trigger=" Teléfonos" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'Numero'},
                        {nombre:'Tipo'},
                        {nombre:'Fuente'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={DetallePersona.telefonosPersona} 
                namelist={[
                    'telefonoNumero',
                    'telefonoTipoDescripcion',
                    'fuenteNombre',
                    'telefonoFhReferencia'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            <Collapsible trigger=" Correos" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'Cuenta'},
                        {nombre:'Fuente'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={DetallePersona.correos} 
                namelist={[
                    'correoCuenta',
                    'fuenteNombre',
                    'correoFhRelacion'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            </div>
        
        </div>},
        {id:2,nombre:
        <div>
            <CardInfoPersonas />
            <Button
                OnClick={() => getReportePersona()}
                id="TraerTodosID"
                name="TraerTodosName"
                insideNameButton="Imprimir Reporte"
                FontIconClass="done"
            />
            <div className="TableComponent">
            <Collapsible trigger="Relacionados" open>
                <TableComponent 
                tabsInfo={[
                    
                        {nombre:'Foto'},
                        {nombre:'Fuente'},
                        {nombre:'Documento'},
                        {nombre:'Nombre Completo'},
                        {nombre:'Relacion'},
                        {nombre:'Fecha Relacion'}
                ]} 
                tabsPanel={Relacionados} 
                namelist={[
                    'fotoUrl',
                    'fuenteNombre',
                    'documentoNumero',
                    'nombreCompleto',
                    'tipoRelacionDescripcion',
                    'relacionFecha'
                    ]} 
                OnChange={SetPersonDetalle}/>
            </Collapsible>
            <Collapsible trigger="Historial Laboral" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Institucion'},
                        {nombre:'Profesion'},
                        {nombre:'Designacion'},
                        {nombre:'Estatus'},
                        {nombre:'Fecha Entrada'},
                        {nombre:'Fecha Designacion'},
                        {nombre:'Fecha Creacion'}
                ]} 
                tabsPanel={HistorialLaboral} 
                namelist={[
                    'institucionNombre',
                    'profesionNombre',
                    'designacionNombre',
                    'estatusNombre',
                    'fechaEntrada',
                    'fechaDesignacion',
                    'fechaCreacion'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            <Collapsible trigger="Empresas" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'RNC'},
                        {nombre:'Nombre'},
                        {nombre:'Tipo'},
                        {nombre:'Numero Acciones'},
                        {nombre:'Fecha'}
                ]} 
                tabsPanel={Empresas} 
                namelist={[
                    'empresaIdentificacion',
                    'empresaNombre',
                    'tipoRelacionDescripcion',
                    'numeroAcciones',
                    'fechaRegistro'
                    ]} 
                OnChange={SetEmpresaDetalle}/>
            </Collapsible>
            <Collapsible trigger="Bienes" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'Nombre'},
                        {nombre:'Valor'},
                        {nombre:'Direccion'},
                        {nombre:'Municipio'},
                        {nombre:'Provincia'},
                        {nombre:'Tipo'},
                        {nombre:'Fecha creacion'},
                        {nombre:'Fecha'}
                ]} 
                tabsPanel={Bienes} 
                namelist={[
                    'bienComercial',
                    'bienValor',
                    'bienDireccion',
                    'bienMunicipio',
                    'bienProvincia',
                    'bienTipo',
                    'bienFecha',
                    'bienCreacion'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            <Collapsible trigger="Vehiculos" open>
                <TableComponent 
                tabsInfo={[
                        {nombre:'Clase'},
                        {nombre:'Marca'},
                        {nombre:'Modelo'},
                        {nombre:'Año'},
                        {nombre:'Placa'},
                        {nombre:'Chasis'},
                        {nombre:'Color'},
                        {nombre:'Fecha'},
                        {nombre:'Fecha relación'}
                ]} 
                tabsPanel={DetallePersona.vehiculos} 
                namelist={[
                    'claseDescripcion',
                    'marcaDescripcion',
                    'modeloDescripcion',
                    'vehiculoAno',
                    'vehiculoPlaca',
                    'vehiculoChasis',
                    'colorDescripcion',
                    'vehiculoFechaCreacion',
                    'vehiculoFhEmision'
                    ]} 
                OnChange={SetVehiculoDetalle}/>
            </Collapsible>

            </div>
        </div>},
        {id:3,nombre:
        <div>
            <CardInfoPersonas />
            <Button
                OnClick={() => getReportePersona()}
                id="TraerTodosID"
                name="TraerTodosName"
                insideNameButton="Imprimir Reporte"
                FontIconClass="done"
            />
            <div className="TableComponent">
            <Collapsible trigger="Fichas" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Numero Caso'},
                        {nombre:'Numero Ficha'},
                        {nombre:'Fecha relación'},
                        {nombre:'Infraccion'},
                        {nombre:'Lugar Hecho'},
                        {nombre:'Relato'}
                ]} 
                tabsPanel={Fichas} 
                namelist={[
                    'casoNumero',
                    'fichaNo',
                    'fecha',
                    'infraccion',
                    'lugarHecho',
                    'relato'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            <Collapsible trigger="Casos" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Codigo'},
                        {nombre:'Entidad'},
                        {nombre:'Numero Expediente'},
                        {nombre:'Referencia'},
                        {nombre:'Infraccion'},
                        {nombre:'Involucramiento'},
                        {nombre:'Fecha'},
                        {nombre:'Fecha Creacion'},
                        {nombre:'Fecha Actualizacion'}
                ]} 
                tabsPanel={Casos} 
                namelist={[
                    'Codigo',
                    'entidad',
                    'numeroExpediente',
                    'referencia',
                    'infracciones',
                    'tipoInvolucramiento',
                    'casoFecha',
                    'fhCreacion',
                    'fhActualizacion'
                    ]} 
                OnChange={()=> null}/>
            </Collapsible>
            </div>
        
        </div>},
        {id:4,nombre:  
            <div>
            <CardInfoPersonas />
            <Button
                OnClick={() => getReportePersona()}
                id="TraerTodosID"
                name="TraerTodosName"
                insideNameButton="Imprimir Reporte"
                FontIconClass="done"
            />
            <div className="TableComponent">
            <Collapsible trigger=" Galeria" open>
                <div className="row"  style={{display: 'flex'}}>
                    {FotosPersona.map(item =>(
                        <p style={{width: '180px'}}>
                            <img src={item.fotoRuta} style={{width: '100px',  height:'100px'}}/>
                            <p>Fuente: {item.fuenteNombre }</p>
                            <p>Fecha: </p>
                            <p>{item.fotoFhReferencia}</p>
                        </p>
                    ))}
                </div>
            </Collapsible>
            </div>
        </div>     
       },
        {id:5,nombre:  
            <div>
            <CardInfoPersonas />
            <Button
                OnClick={() => getReportePersona()}
                id="TraerTodosID"
                name="TraerTodosName"
                insideNameButton="Imprimir Reporte"
                FontIconClass="done"
            />
            <div className="TableComponent">
            <Collapsible trigger="Relacionados" open>
            <TableComponent 
                tabsInfo={[
                        {nombre:'Fuente'},
                        {nombre:'Documento'},
                        {nombre:'Nombre Completo'},
                        {nombre:'Relacion'},
                        {nombre:'Fecha Relacion'}
                ]} 
                tabsPanel={Relacionados} 
                namelist={[
                    'fuenteNombre',
                    'documentoNumero',
                    'nombreCompleto',
                    'tipoRelacionDescripcion',
                    'relacionFecha'
                    ]} 
                OnChange={SetPersonDetalle}/>
            </Collapsible>
            </div>
        </div>      
       },
    ]
       return (
           <div className="ResultadoBusquedaPersona">
            {
                personas.length > 0
                ? 
                <a onClick={GoToListado}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar a lista de personas"/></a>
                : 
                null      
            }
        {
        Visible
        ?<ReportView 
            reporte={Reporte}
            hide={hidereport}
            />
        :null
      }

            <div>
             <TabsComponent tabsInfo={tabsInfo} tabsPanel={tabsPanel}/>
            </div>
        </div>
        );
    }



export default DetallePersona;
