import { useEffect, useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import  Contenedor  from '../../components/Contenedor';
import axios from '../../base/axiosconfig';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DetallePersonaAction } from '../../redux/actions/Personas/detallePersonaActions';
import { IDetallePersona } from '../../redux/interfaces/Personas/IDetallePersona';
import { PersonasAction } from '../../redux/actions/Personas/personasActions';
import swal from 'sweetalert'
import useGetDetalleVehiculo from '../../hooks/Vehiculos/useGetDetalleVehiculo';
import useGetVehiculos from '../../hooks/Vehiculos/useGetVehiculos';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { EmpresaDetalleAction, EmpresasAction } from '../../redux/actions/Empresas/empresasActions';
import { IEmpresa } from '../../redux/interfaces/Empresas/IEmpresa';

let Empresa : IEmpresa ={
  empresaId:-1,
  empresaIdentificacion :'',
  empresaNombre:'',
  empresaNombreComercial:'',
  actividadComercialNombre:'',
  fhIncorporacion:new Date ,
  fhCancelacion:new Date,
  tipoEmpresa:'',
  fhCreacion:new Date,
}


let Persona : IDetallePersona = {
  personaId: -1,
  documento:'',
  nombres:'',
  apellido1:'',
  apellido2:'',
  nombrecompleto:'',
  fhNacimiento:new Date,
  edad:-1,
  sexo:'',
  genero:'',
  fhCreacion:new Date,
  lugarNacimiento:'',
  nacionalidadDescripcion: '',
  estatusDescripcion:'',
  fotoRuta:'',
  correos:[],
  direcciones:[],
  telefonosPersona:[],
  vehiculos:[]
}

const  BusquedaVehiculo = () => {

    useEffect(() => {
        /*GetColor()
        GetListMarcas()*/
    }, [])


    const history = useHistory();
    const dispatch = useDispatch();
    const {getDetalleVehiculo} = useGetDetalleVehiculo()
    const {getVehiculos} = useGetVehiculos()
    const [NombrePropietario,SetNombrePropietario] = useState('')
    const [PersonaId,SetPersonaId] = useState(-1)
    const [MarcaVehiculo,SetMarcaVehiculo] = useState('')
    const [PlacaVehiculo,SetPlacaVehiculo] = useState('')
    const [ChasisVehiculo,SetChasisVehiculo] = useState('')
    const [ModeloVehiculo,SetModeloVehiculo] = useState('')
    const [AñoVehiculo,SetAñoVehiculo] = useState(-1)
    const [ColorVehiculo,SetColorVehiculo] = useState('')
    const [ClaseVehiculo,SetClaseVehiculo] = useState('')
    const [ListColorVehiculo,SetListColorVehiculo] = useState([])
    const [ListMarcaVehiculo,SetListMarcaVehiculo] = useState([])
    const [ListModeloVehiculo,SetListModeloVehiculo] = useState([])

    const HundlerInput = (event:any) => {
        switch (event.name) {
          case "NombrePropietario":
            SetNombrePropietario(event.value);
            break;
            case "cedula":
              ConsultarPersona(event.value);
            break;
            case "marcaDescripcion":
                SetMarcaVehiculo(event.value);
            break;
            case "PlacaVehiculo":
                SetPlacaVehiculo(event.value);
            break;
            case "ChasisVehiculo":
                SetChasisVehiculo(event.value);
            break;
            case "modeloDescripcion":
                SetModeloVehiculo(event.value);
            break;
            case "AñoVehiculo":
                SetAñoVehiculo(parseInt(event.value));
            break;
            case "colorDescripcion":
                SetColorVehiculo(event.value);
            break;
            case "ClaseVehiculo":
                SetClaseVehiculo(event.value);
            break;
        }
      };

      const GetColor = () => {
        axios.get(`/api/Vehiculos/GetColor`)
            .then(function (response) {
              let ListColor = response.data
              ListColor.unshift({
                colorId:-1,
                colorDescripcion:"Seleccione un Color"
                })
                SetListColorVehiculo(ListColor)
            })
            .catch(function (error) {
              console.log(error);
            });
      }

      const GetListMarcas = () => {
        axios.get(`/api/Vehiculos/GetMarca`)
            .then(function (response) {
              let ListMarcas = response.data
              ListMarcas.unshift({
                marcaId:-1,
                marcaDescripcion:"Seleccione una Marca"
                })
                SetListMarcaVehiculo(ListMarcas)
            })
            .catch(function (error) {
              console.log(error);
            });
      }

      const GetListModelo = (marcaId:number) => {
        axios.get(`/api/Vehiculos/GetModelo/${marcaId}`)
            .then(function (response) {
              let ListModelo = response.data
              ListModelo.unshift({
                modeloId:-1,
                modeloDescripcion:"Seleccione un Modelo"
                })
                SetListModeloVehiculo(ListModelo)
            })
            .catch(function (error) {
              console.log(error);
            });
      }
      
      const ConsultarPersona = (documento:string) => {

        axios.post(`/api/Personas/Buscar-Personas`, {
          documento,
          tipoDocumento: 1
          })
          .then(function (response) {
            SetPersonaId(response.data[0].personaId)
          })
          .catch(function (error) {
            SetPersonaId(-1)
          });
    }
    const BuscarVehiculo = () => {
      dispatch(PersonasAction([]))
      dispatch(DetallePersonaAction(Persona))
      dispatch(EmpresasAction([]))
      dispatch(EmpresaDetalleAction(Empresa))
        if (PlacaVehiculo.length === 7 || ChasisVehiculo.length >= 17){
          dispatch(LoadingAction(true))
          getDetalleVehiculo(PlacaVehiculo,ChasisVehiculo,PersonaId)
          .then(()=> 
          {
          history.push("/Home/DetalleVehiculo");
            //dispatch(LoadingAction(false))
          })
          .catch(error=>
          {  
            dispatch(LoadingAction(false))
            swal("Busqueda Vehiculo", error, "info")
          });

        }else{
          if (ValidarFiltros() != false) {
            dispatch(LoadingAction(true))
            getVehiculos(ColorVehiculo,AñoVehiculo,ModeloVehiculo,MarcaVehiculo,PlacaVehiculo)
            .then(()=> 
            {
              history.push("/Home/ResultadoBusquedaVehiculo");
              dispatch(LoadingAction(false))
            })
            .catch(error=>
            {  
              dispatch(LoadingAction(false))
              swal("Busqueda Vehiculo", error, "info")
            });
          }
        }
    }    

    const ValidarFiltros = () =>{
      if(MarcaVehiculo === '') {
        swal("Busqueda Vehiculos", "El campo Marca Vehiculo es obligatorio si la placa no esta completa", "info");
        return false;
      }else if(ModeloVehiculo === '') {
        swal("Busqueda Vehiculos", "El campo Modelo Vehiculo es obligatorio si la placa no esta completa", "info");
        return false;
      }else if (ColorVehiculo === '') {
        swal("Busqueda Vehiculos", "El campo Color Vehiculo es obligatorio si la placa no esta completa", "info");
        return false;
      }else if(AñoVehiculo == -1) {
        swal("Busqueda Vehiculos", "El campo Año Vehiculo es obligatorio si la placa no esta completa", "info");
        return false;
      }else if(PlacaVehiculo === '') {
        swal("Busqueda Vehiculos", "El campo Placa Vehiculo es obligatorio si la placa no esta completa", "info");
        return false;
      }
    }

    return (
        <div className="BuscarPersonas">
            <BreadCrumb link1="../Home/busquedaVehiculo" infoContenidoBreadcrumb="Busqueda Vehiculo"/>
            <Contenedor className="" headerName="Criterios de búsqueda" subHeaderName="FAVOR LLENAR LOS CRITERIOS DE BúSQUEDA">
                <div className="class-contenedor">
                     {/*<Input OnChange={HundlerInput} label="" type="text" id="NombrePropietario" name="NombrePropietario" placeholder="Nombre Propietario/a" />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="cedula" name="cedula" placeholder="Cédula Propietario/a" maxLength="11"/>
                    <Input OnChange={HundlerInput} label="" type="text" id="PlacaVehiculo" name="PlacaVehiculo" placeholder="Placa Vehiculo" maxLength="7"/>
                    <Input OnChange={HundlerInput} label="" type="text" id="ChasisVehiculo" name="ChasisVehiculo" placeholder="Chasis Vehiculo" />
                    {/*<Input OnChange={HundlerInput} label="" type="text" id="ClaseVehiculo" name="ClaseVehiculo" placeholder="Clase Vehiculo" />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="marcaDescripcion" name="marcaDescripcion" placeholder="Marca" />
                    {/*<Select OnChange={HundlerInput} id="marcaId" name="marcaDescripcion" Placeholder="MarcaVehiculo" options={ListMarcaVehiculo} />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="modeloDescripcion" name="modeloDescripcion" placeholder="Modelo" />
                    {/*<Select OnChange={HundlerInput} id="modeloId" name="modeloDescripcion" Placeholder="ModeloVehiculo" options={ListModeloVehiculo} /> />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="colorDescripcion" name="colorDescripcion" placeholder="Color" />
                    {/*<Select OnChange={HundlerInput} id="colorId" name="colorDescripcion" Placeholder="ColorVehiculo" options={ListColorVehiculo} /> />*/}
                    <Input OnChange={HundlerInput} label="" type="text" id="AñoVehiculo" name="AñoVehiculo" placeholder="Año Vehiculo" />
                    <div>
                        <Button OnClick={BuscarVehiculo} id="BuscarVehiculoID" name="BuscarVehiculo" insideNameButton="Buscar Vehiculo" FontIconClass="search" />
                    </div>
                </div>
            </Contenedor>
        </div>
        );
}

export default BusquedaVehiculo;
