import { useDispatch } from "react-redux";
import axios from "../../base/axiosconfig";
import {EmpresasAction } from "../../redux/actions/Empresas/empresasActions";
import { IEmpresa } from "../../redux/interfaces/Empresas/IEmpresa";

let Empresa : IEmpresa[] = []

const useGetEmpresaByNombre = () => {
  const dispatch = useDispatch();

  const getEmpresaByNombre = (nombre:string) =>{
    return new Promise<any>((resolve,reject)=>{
      axios.get(`/api/Busqueda/GetEmpresasByNombre/${nombre}`,)
        .then(function (response) {
          if(response.data.length > 0){
            dispatch(EmpresasAction(response.data));
            resolve(()=>null)
          }else{
            dispatch(EmpresasAction(Empresa));
            reject("No se encontro ninguna empresa")
             }     })
        .catch(function (error) {
          console.log(error)
          reject(error)
        });
    })

      }
        return{
          getEmpresaByNombre
        }
}

export default useGetEmpresaByNombre

