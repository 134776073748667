import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import  BreadCrumb  from '../../components/breadcrumb';
import CardInfoPersonasVehiculo from '../../components/Vehiculos/cardInfoPersonasVehiculo';
import CardResultadoDetalleVehiculo from '../../components/Vehiculos/CardResultadoDetalleVehiculo';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { AppState } from '../../redux/reducers/rootReducer';

const DetalleVehiculo = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    
    const {DetalleVehiculo} = useSelector((state: AppState) => state.vehiculosDetalle);
    const {Vehiculos} = useSelector((state: AppState) => state.vehiculos);
    const {DetalleEmpresas} = useSelector((state: AppState) => state.EmpresaDetalle);
    const {DetallePersona} = useSelector((state: AppState) => state.detallePersona);

    const GoListado = () =>{
        history.push("/Home/ResultadoBusquedaVehiculo");
    }
    const GoDetalleEmpresa = () =>{
        history.push("/Home/DetalleEmpresa");
        dispatch(LoadingAction(true))
    }
    const GoDetallePersona = () =>{
        history.push("/Home/DetallePersona");
        dispatch(LoadingAction(true))
    }
    return (
        <div className="DetalleVehiculo">
            {
                Vehiculos.length > 0
                ? 
                <a onClick={GoListado}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar a los Resultados"/></a>
                : 
                null      
            }
                                    {
                DetalleEmpresas.empresaId != -1
                ? 
                <a onClick={()=>GoDetalleEmpresa()}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar al detalle Empresa"/></a>
                : 
                null      
            }
                                    {
                DetallePersona.personaId != -1
                ? 
                <a onClick={()=>GoDetallePersona()}><BreadCrumb link1="#" infoContenidoBreadcrumb="Regresar al detalle Persona"/></a>
                : 
                null      
            }
     
         <h4>REGISTROS ENCONTRADOS</h4>
        {DetalleVehiculo.length > 0
                ? 
                <div>
                <div>
                <div>
                    <CardResultadoDetalleVehiculo  
                    info={DetalleVehiculo[0]}
                    />
                </div>
                </div>
                <div>
                    {DetalleVehiculo.map(item=>(
                      <div>
                        <h4>PROPIETARIO/A/</h4>
                           <CardInfoPersonasVehiculo entidad={item}/>
                        </div>
                    ))}
                    </div>
                    </div>
                : 
                <h4>CARGANDO...</h4>
            }

    </div>
    );
}



export default DetalleVehiculo;
