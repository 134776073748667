import { IPersonaBusquedaMasiva } from "../../interfaces/BusquedaMasiva/IPersonaBusquedaMasiva";
import { GET_PERSONAS_CONSULTA_MASIVA } from "../actionTypes";

export interface BusquedaMasivaStateType {
    personasBusquedaMasiva: IPersonaBusquedaMasiva[];
}

export interface BusquedaMasivaActionType {
  type: typeof GET_PERSONAS_CONSULTA_MASIVA;
  payload: IPersonaBusquedaMasiva[];
}

export const BusquedaMasivaAction = (personasBusquedaMasiva: IPersonaBusquedaMasiva[]): BusquedaMasivaActionType => {
  return {
    type: GET_PERSONAS_CONSULTA_MASIVA,
    payload: personasBusquedaMasiva
  };
};