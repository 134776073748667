//Personas
export const GET_PERSONAS = 'GET_PERSONAS';
export const GET_PERSONA_DETALLE = 'GET_PERSONA_DETALLE';

//Usuarios
export const LOGIN_USUARIO = 'LOGIN_USUARIO';
export const LOGOUT_USUARIO = 'LOGOUT_USUARIO';
export const GET_USUARIO = 'GET_USUARIO';
export const GET_ALL_USUARIOS = 'GET_ALL_USUARIOS';

//Vehiculos
export const GET_VEHICULO_DETALLE = 'GET_VEHICULO_DETALLE';
export const GET_VEHICULOS = 'GET_VEHICULOS';

//Empresas
export const GET_EMPRESA = 'GET_EMPRESA';
export const GET_EMPRESA_DETALLE = 'GET_EMPRESA_DETALLE';

//Telefonos
export const GET_PERSONAS_TELEFONO = 'GET_PERSONAS_TELEFONO';

//ConsultaMasiva
export const GET_PERSONAS_CONSULTA_MASIVA = 'GET_PERSONAS_CONSULTA_MASIVA';

//Configuracion
export const SET_LOADING = "SET_LOADING"