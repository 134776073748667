import { useSelector } from 'react-redux';
import  BreadCrumb  from '../../components/breadcrumb';
import  CardResultadoVehiculo  from '../../components/Vehiculos/CardResultadoVehiculo';
import { AppState } from '../../redux/reducers/rootReducer';

const ResultadoBusquedaVehiculo = () => {

    const {Vehiculos} = useSelector((state: AppState) => state.vehiculos);

       return (
        <div className="ResultadoBusquedaPersona">
            <BreadCrumb link1="../Home/ResultadoBusquedaVehiculo" infoContenidoBreadcrumb="Resultado Busqueda Vehiculo"/>
            <h4>REGISTROS ENCONTRADOS: {Vehiculos.length}</h4>
            <div>
            {Vehiculos.length > 0
                ? 
                Vehiculos.map(item =>(
                    <CardResultadoVehiculo  
                        info={item}
                    />))
                : 
                <h4>CARGANDO...</h4>
            }
            </div>
        </div>
        );
    }



export default ResultadoBusquedaVehiculo;
