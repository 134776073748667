import { Route, useLocation } from "react-router-dom";
import Login from '../views/login';
import Home from '../views/Home';
import {useState,useEffect} from 'react'
import '../style/custom.scss'
import { useHistory} from "react-router-dom";

const PrivateRoutes = () =>{
  return (
    <div>
      <Route path="/Home" component={Home}/>
    </div>
  )
}

const PublicRoutes = () =>{
  return (
    <div>
    <Route path="/Login" component={Login}/>
  </div>

  )
}

const Router = () => {

const [Authenticated,SetAuthenticated] = useState(false);

const history = useHistory();
const location = useLocation();

const CheckAuthentication = () => {

  if(localStorage.getItem('token') != null){
    let fechaActual = new Date()
    let fechaString : any  =  localStorage.getItem("fecha")?.toString()
    let fechaExpiracion : any = new Date(fechaString)

    if (fechaExpiracion < fechaActual) {
      SetAuthenticated(false)
      localStorage.clear()
      history.push("/Login");
    }else{
      SetAuthenticated(true)
      if (location.pathname.toLocaleLowerCase() == "/login" || location.pathname.toLocaleLowerCase() == "/") {
        history.push("/Home");
      }
    }
  }else{
    SetAuthenticated(false)
    localStorage.clear()
    history.push("/Login");
  }

}

useEffect(() => {
  CheckAuthentication()
}, [location.pathname])

  return (
    <div>
      {
        Authenticated
        ?<PrivateRoutes/>
        :<PublicRoutes/>
      }
    </div>
    )
}
export default Router;