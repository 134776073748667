import React, { Component } from 'react';

export class LinkComponent extends Component {
    props = {
        id: '',
        name: '',
        href: '',
        infoContenido:''
    }

    state = {
        value: ''
    }

    render() {
        return (
            <a href={this.props.href} id={this.props.id} name={this.props.name} class="active">
                {this.props.infoContenido}
            </a>
        );
    }
}