import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';
import CardInfoPersonasTelefonos from '../../components/Telefonos/CardInfoPersonasTelefonos'

const DetalleTelefono = () => {
    
    const {PersonasTelefono} = useSelector((state: AppState) => state.PersonasTelefono);

    return (
        <div className="DetalleVehiculo">
     
         <h4>REGISTROS ENCONTRADOS</h4>
        {PersonasTelefono.length > 0
                ? 
                <div>
                <div>
                    {PersonasTelefono.map(item=>(
                      <div>
                        <h4>PROPIETARIO/A/</h4>
                           <CardInfoPersonasTelefonos persona={item}/>
                        </div>
                    ))}
                    </div>
                    </div>
                : 
                <h4>CARGANDO...</h4>
            }

    </div>
    );
}



export default DetalleTelefono;
