import { GET_PERSONAS_TELEFONO} from '../../actions/actionTypes';
import { PersonasTelefonoStateType, PersonasTelefonoActionType } from '../../actions/Telefonos/PersonaTelefonosActions';
  
  const initialStateposts: PersonasTelefonoStateType = {
    PersonasTelefono: []
  };
  
const PersonasTelefonoReducer = (state = initialStateposts, action: PersonasTelefonoActionType): PersonasTelefonoStateType => {
    switch (action.type) {
      case GET_PERSONAS_TELEFONO:
        return {
          ...state,
          PersonasTelefono: action.payload
        };
      default:
        return state;
    }
  }

  export default PersonasTelefonoReducer;