import './style/custom.scss'
import { BrowserRouter } from "react-router-dom";
import Router from './views/Router';
import Loading from "./components/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './redux/reducers/rootReducer';


function App() {

  const {Visible} = useSelector((state: AppState) => state.Loading);

return (
<div>
{
        Visible
        ?<Loading/>
        :null
      }

  <BrowserRouter>
    <Router/>
  </BrowserRouter>
</div>
  )
}

export default App;