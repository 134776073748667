
import { GET_USUARIO} from "../../actions/actionTypes";
import { UsuarioActionType, UsuarioStateType } from "../../actions/Usuarios/UsuarioAction";

  const initialState: UsuarioStateType = {
    Usuario: {
        usuarioid: -1,
        usuarioLogin: "",
        usuarioNombres: "",
        usuarioCargo: "",
        grupoDescripcion: "",
        afiliadoDescripcion: "",
        usuarioUltimaFhSesion: new Date
    }
  };
  
const UsuarioReducer = (state = initialState, action: UsuarioActionType): UsuarioStateType => {
    switch (action.type) {
      case GET_USUARIO:
        return {
          ...state,
          Usuario: action.payload
        };
      default:
        return state;
    }
  }

  export default UsuarioReducer;