import { useEffect, useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import Contenedor  from '../../components/Contenedor';
import axios from '../../base/axiosconfig';
import { useHistory } from 'react-router-dom';
import Select from '../../components/Select';
import swal from 'sweetalert'
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';
import { useDispatch } from 'react-redux';

const CrearUsuario = () =>{
  
    let history = useHistory();
    const dispatch = useDispatch();

    const [enabled,Setenabled] = useState(true)
    const [PersonaId,SetPersonaId] = useState(-1)
    const [UsuarioLogin,SetUsuarioLogin] = useState('')
    const [UsuarioNombres,SetUsuarioNombres] = useState('')
    const [UsuarioClave,SetUsuarioClave] = useState('')
    const [ConfirmacionClave,SetConfirmacionClave] = useState('')
    const [UsuarioCorreo,SetUsuarioCorreo] = useState('')
    const [UsuarioCargo,SetUsuarioCargo] = useState('')
    const [UsuarioTelefono,SetUsuarioTelefono] = useState('')
    const [Motivo,SetMotivo] = useState('')
    const [GrupoId,SetGrupoId] = useState(-1)
    const [ListGrupo,setListGrupo] = useState<Array<any>>([]);
    const [UsuarioConsultas,SetUsuarioConsultas] = useState(-1)
    const [Afilicados,setAfilicados] = useState<Array<any>>([]);
    const [Afilicado,setAfilicado] = useState<any>();
    const [AfilicadoId,setAfilicadoId] = useState(-1);
    

    useEffect(()=>{
      GetListGrupo()
      GetAfilicados()
    },[])

    useEffect(()=>{
      SetDataByAfiliado()
    },[AfilicadoId])

    const GetListGrupo = () => {
      axios.get(`/api/Grupos/Get-Grupos`)
          .then(function (response) {
              setListGrupo(response.data)
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const GetAfilicados = () => {
      axios.get(`/api/afiliados/Get-Afiliados`)
          .then(function (response) {
              setAfilicados(response.data)
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const HundlerInput = (event:any) => {
      switch (event.name) {
        case "cedula":
            if(event.value.length === 11)
              ConsultarPersona(event.value);
          break;
          case "afiliadoDescripcion":
              setAfilicadoId(parseInt(event.value))
            break;
          case "UsuarioLogin":
            SetUsuarioLogin(event.value);
            break;
            case "UsuarioNombres":
            SetUsuarioNombres(event.value);
            break;
            case "UsuarioClave":
            SetUsuarioClave(event.value);
            break;
            case "ConfirmacionClave":
            SetConfirmacionClave(event.value);
            break;
            case "UsuarioCorreo":
            SetUsuarioCorreo(event.value);
            break;
            case "UsuarioCargo":
            SetUsuarioCargo(event.value);
            break;
            case "UsuarioTelefono":
            SetUsuarioTelefono(event.value);
            break;
            case "Motivo":
            SetMotivo(event.value);
            break;
            case "nombre":
              SetGrupoId(parseInt(event.value));
            break;
            case "CantidadConsulta":
              SetUsuarioConsultas(parseInt(event.value));
            break;
        }
      };

      const ConsultarPersona = (documento:string) => {
          dispatch(LoadingAction(true));
          axios.post(`/api/Personas/Buscar-Personas`, {
            documento,
            tipoDocumento: 1
            })
            .then(function (response) {
              if (response.data.length > 0) {
                SetPersonaId(response.data[0].personaId)
                swal("Login", "Cedula Correcta, Continue.", "success");
                Setenabled(false);
              }
              dispatch(LoadingAction(false));
            })
            .catch(function (error) {
              swal("Login", "No se encontro la persona", "info");
              dispatch(LoadingAction(false));
            });
      }
      const SetDataByAfiliado = () => {
        if (AfilicadoId != -1) {
          let AfilicadoSelect= Afilicados.find(element=> element.afiliadoId = AfilicadoId)
          setAfilicado(AfilicadoSelect);
          SetUsuarioConsultas(parseInt(AfilicadoSelect.afiliadoConsultas));
        }
      }

      const CearUsuario = () => {
        if (ValidarFiltros() != false) {
          if (UsuarioClave === ConfirmacionClave) {
            dispatch(LoadingAction(true));
            axios.post(`/api/usuarios/add-usuario`, {
              PersonaId,
                UsuarioLogin,
                UsuarioNombres,
                UsuarioClave,
                ConfirmacionClave,
                UsuarioCorreo,
                UsuarioCargo,
                UsuarioTelefono,
                Motivo,
                GrupoId,
                UsuarioConsultas
              })
              .then(function (response) {
                if (response.data === "El usuario ya existe"){
                  swal("Usuarios", "El usuario ya existe", "info");
                  
                 dispatch(LoadingAction(false));
                }else{
                  swal("Usuarios", "Usuario Creado exitosamente", "success");
                  history.push("/Home/ListaUsuarios");
                  
                  dispatch(LoadingAction(false));
                }
              })
              .catch(function (error) {
                swal("Usuarios", "Ocurrio un error al crear el usuario", "error");
                
                dispatch(LoadingAction(false));
              });
          }else{
            swal("Usuarios", "Las claves de usuario no coinciden", "info");
          }
        }
      }

      const ValidarFiltros = () =>{
        if(PersonaId == -1) {
          swal("Usuarios", "Debe ingresar una cedula valida", "info");
          return false;
        }else if(GrupoId == -1) {
          swal("Usuarios", "El campo Grupo es obligatorio", "info");
          return false;
        }else if(UsuarioLogin == '') {
          swal("Usuarios", "El campo Nombre de usuario es obligatorio", "info");
          return false;
        }else if(UsuarioNombres == '') {
          swal("Usuarios", "El campo Nombre es obligatorio", "info");
          return false;
        }else if (UsuarioClave == '') {
          swal("Usuarios", "El campo Clave es obligatorio", "info");
          return false;
        }else if (ConfirmacionClave == '') {
          swal("Usuarios", "El campo Confirmacion Clave es obligatorio", "info");
          return false;
        }else if(UsuarioCorreo == '') {
          swal("Usuarios", "El campo Correo es obligatorio", "info");
          return false;
        }else if(UsuarioTelefono === '') {
          swal("Usuarios", "El campo Telefono es obligatorio", "info");
          return false;
        }else if(UsuarioCargo == '') {
          swal("Usuarios", "El campo Cargo es obligatorio", "info");
          return false;
        }
    }

       return (
        <div className="BuscarPersonas">
        <BreadCrumb link1="/Home/CrearUsuario" infoContenidoBreadcrumb="Crear Usuario"/>
          <Contenedor className="" headerName="CREACION DE USUARIOS" subHeaderName="MANTENIMIENTO USUARIOS">
          <div className="class-contenedor">
                <Input OnChange={HundlerInput} label="" type="text" id="cedula" name="cedula" placeholder="Cedula" maxLength="11"/>
                <Select OnChange={HundlerInput} disabled =  {(enabled)? "disabled" : ""} id="afiliadoId" name="afiliadoDescripcion" Placeholder="Afiliado" options={Afilicados} />
                <Select OnChange={HundlerInput} disabled =  {(enabled)? "disabled" : ""} id="id" name="nombre" Placeholder="Grupo" options={ListGrupo} />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioLogin} label="" type="text" id="UsuarioLogin" name="UsuarioLogin" placeholder="Nombre Usuario" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioNombres} label="" type="text" id="UsuarioNombres" name="UsuarioNombres" placeholder="Nombre Completo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioClave} label="" type="password" id="UsuarioClave" name="UsuarioClave" placeholder="Clave" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={ConfirmacionClave} label="" type="password" id="ConfirmacionClave" name="ConfirmacionClave" placeholder="Repetir Clave" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioCorreo} label="" type="text" id="UsuarioCorreo" name="UsuarioCorreo" placeholder="Correo Electronico" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioCargo} label="" type="text" id="UsuarioCargo" name="UsuarioCargo" placeholder="Usuario Cargo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioTelefono} label="" type="number" id="UsuarioTelefono" name="UsuarioTelefono" placeholder="Telefono" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={Motivo} label="" type="text" id="Motivo" name="Motivo" placeholder="Motivo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioConsultas} label="" type="number" id="CantidadConsulta" name="CantidadConsulta" placeholder="Cantidad Consultas" />
                <Button type={"submit"} disabled = {(enabled)? "disabled" : ""} OnClick={CearUsuario} id="BuscarPersonaID" name="BuscarPersonaName" insideNameButton="Crear usuario" FontIconClass="search" />
            </div>
        </Contenedor>
    </div>
    );
}
export default CrearUsuario
