import { useDispatch } from "react-redux";
import axios from "../../base/axiosconfig";
import { VehiculosAction } from "../../redux/actions/Vehiculos/vehiculosActions";

const useGetVehiculos = () =>{
    const dispatch = useDispatch()
    const getVehiculos = (   
        color:string,
          Ano:number,
          modelo:string,
          marca:string,
          Placa:string
      ) => {
        return new Promise<any>((resolve,reject)=>{
            axios.post(`/api/Busqueda/Buscar-Vehiculo`, {
              color,
              Ano,
              modelo,
              marca,
              Placa
              },)
              .then(function (response) {
                if(response.data.length > 0){
                    
                    dispatch(VehiculosAction(response.data));
                    resolve(()=>null)
                }else{
                    dispatch(VehiculosAction([]));
                    reject("No se encontro ningun vehiculo")
                }
                dispatch(VehiculosAction(response.data));
                resolve(()=>null)
              })
              .catch(function (error) {
                  console.log(error)
                  reject(error)
              });
            });
        }
        return{
            getVehiculos
        }
}

export default useGetVehiculos