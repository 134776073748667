import { IPersona } from "../../interfaces/Personas/IPersona";
import { GET_PERSONAS } from "../actionTypes";

export interface PersonasStateType {
    personas: IPersona[];
}

export interface PersonasActionType {
  type: typeof GET_PERSONAS;
  payload: IPersona[];
}

export const PersonasAction = (personas: IPersona[]): PersonasActionType => {
  return {
    type: GET_PERSONAS,
    payload: personas
  };
};