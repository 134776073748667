
const Button = ({type,OnClick,id,name,insideNameButton,FontIconClass,href,disabled} : any) =>{

    return (
        <a href={href} className="active">
            <button type={type} onClick={OnClick} id={id} name={name} disabled={disabled}>
                <span className="material-icons">{FontIconClass}</span>{insideNameButton}
            </button>
        </a>
    );
}
export default Button