import  { useEffect, useState } from 'react';
import  Input  from '../../components/Input';
import  Button  from '../../components/Button';
import { BreadCrumb } from '../../components/breadcrumb';
import Contenedor  from '../../components/Contenedor';
import axios from '../../base/axiosconfig';
import { useHistory } from 'react-router-dom';
import Select from '../../components/Select';
import swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';
import { LoadingAction } from '../../redux/actions/Configuracion/LoadingActions';

interface IGrupo {
  Id: number;
  Nombre : string
}

interface IAfiliado {
  afiliadoId: number;
  afiliadoDescripcion : string
}

const EditarUsuario = () =>{

  const {Usuario} = useSelector((state: AppState) => state.Usuario);
  
    let history = useHistory();
    const dispatch = useDispatch()
    const [enabled,Setenabled] = useState(false)
    
    const [usuarioid,Setusuarioid] = useState(-1)
    const [PersonaId,SetPersonaId] = useState(-1)
    const [UsuarioLogin,SetUsuarioLogin] = useState('')
    const [UsuarioNombres,SetUsuarioNombres] = useState('')
    const [UsuarioClave,SetUsuarioClave] = useState('')
    const [ConfirmacionClave,SetConfirmacionClave] = useState('')
    const [UsuarioCorreo,SetUsuarioCorreo] = useState('')
    const [UsuarioCargo,SetUsuarioCargo] = useState('')
    const [UsuarioTelefono,SetUsuarioTelefono] = useState('')
    const [Motivo,SetMotivo] = useState('')
    const [GrupoId,SetGrupoId] = useState(-1)
    const [ListGrupo,seListGrupo] = useState<Array<IGrupo>>([]);
    const [UsuarioConsultas,SetUsuarioConsultas] = useState(-1)
    const [Afilicados,setAfilicados] = useState<Array<IAfiliado>>([]);
    const [AfilicadoId,setAfilicadoId] = useState<Array<IAfiliado>>([]);

    useEffect(()=>{
      GetUsuario()
      GetAfilicados()
    },[])


    const GetUsuario = () => {
      
      dispatch(LoadingAction(true));
      axios.get(`/api/usuarios/get-usuario/${Usuario.usuarioid}`)
          .then(function (response) {
            SetUsuario(response.data)
            dispatch(LoadingAction(false));
          })
          .catch(function (error) {
            console.log(error);
            dispatch(LoadingAction(false));
          });
    }

    const SetUsuario = (usuario:any) =>{
      Setusuarioid(usuario.usuarioId)
      SetPersonaId(usuario.personaId)
      SetUsuarioLogin(usuario.usuarioLogin);
      SetUsuarioNombres(usuario.usuarioNombres);
      SetUsuarioCorreo(usuario.usuarioCorreo);
      SetUsuarioCargo(usuario.usuarioCargo);
      SetUsuarioTelefono(usuario.usuarioTelefono);
      SetMotivo(usuario.motivo);
      SetGrupoId(parseInt(usuario.grupoId));
      SetUsuarioConsultas(parseInt(usuario.usuarioConsultas));
      GetListGrupo()
    }

    const GetListGrupo = () => {
      axios.get(`/api/Grupos/Get-Grupos`)
          .then(function (response) {
            seListGrupo(response.data)
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const GetAfilicados = () => {
      axios.get(`/api/afiliados/Get-Afiliados`)
        .then(function (response) {
            setAfilicados(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const HundlerInput = (event:any) => {
      switch (event.name) {
        case "afiliadoDescripcion":
          setAfilicadoId(event.value) 
          SetDataByAfiliado()
          break;
          case "UsuarioLogin":
            SetUsuarioLogin(event.value);
            break;
            case "UsuarioNombres":
            SetUsuarioNombres(event.value);
            break;
            case "UsuarioClave":
            SetUsuarioClave(event.value);
            break;
            case "ConfirmacionClave":
            SetConfirmacionClave(event.value);
            break;
            case "UsuarioCorreo":
            SetUsuarioCorreo(event.value);
            break;
            case "UsuarioCargo":
            SetUsuarioCargo(event.value);
            break;
            case "UsuarioTelefono":
            SetUsuarioTelefono(event.value);
            break;
            case "Motivo":
            SetMotivo(event.value);
            break;
            case "nombre":
              SetGrupoId(parseInt(event.value));
            break;
            case "CantidadConsulta":
              SetUsuarioConsultas(parseInt(event.value));
            break;
        }
      };

      const SetDataByAfiliado = () => {
        Afilicados.find(x=>x.afiliadoId)
      }

      const EditarUsuario = () => {
        if (ValidarFiltros() != false) {
          if (UsuarioClave === ConfirmacionClave) {
            dispatch(LoadingAction(true));
            axios.put(`/api/usuarios/Update-User`, {
                usuarioid,
                PersonaId,
                UsuarioLogin,
                UsuarioNombres,
                UsuarioClave,
                ConfirmacionClave,
                UsuarioCorreo,
                UsuarioCargo,
                UsuarioTelefono,
                Motivo,
                GrupoId,
                UsuarioConsultas
              })
              .then(function (response) {
                console.log(response.data)
                if (response.data === "El usuario ya existe"){
                  swal("Usuarios", "El usuario ya existe", "info");
                }else{
                  swal("Usuarios", "Usuario Actualziado exitosamente", "success");
                  history.push("/Home/ListaUsuarios");
                }
                dispatch(LoadingAction(false));
              })
              .catch(function (error) {
                dispatch(LoadingAction(false));
                console.log(error)
                swal("Usuarios", "error al intentar editar el usuario", "error");
              });
          }else{
            swal("Usuarios", "Las claves de usuario no coinciden", "info");
          }
        }
      }

      const ValidarFiltros = () =>{
        if(GrupoId == -1) {
          swal("Usuarios", "El campo Grupo es obligatorio", "info");
          return false;
        }else if(UsuarioLogin == '') {
          swal("Usuarios", "El campo Nombre de usuario es obligatorio", "info");
          return false;
        }else if(UsuarioNombres == '') {
          swal("Usuarios", "El campo Nombre es obligatorio", "info");
          return false;
        }else if (UsuarioClave == '') {
          swal("Usuarios", "El campo Clave es obligatorio", "info");
          return false;
        }else if (ConfirmacionClave == '') {
          swal("Usuarios", "El campo Confirmacion Clave es obligatorio", "info");
          return false;
        }else if(UsuarioCorreo == '') {
          swal("Usuarios", "El campo Correo es obligatorio", "info");
          return false;
        }else if(UsuarioTelefono === '') {
          swal("Usuarios", "El campo Telefono es obligatorio", "info");
          return false;
        }else if(UsuarioCargo == '') {
          swal("Usuarios", "El campo Cargo es obligatorio", "info");
          return false;
        }
    }

       return (
        <div className="BuscarPersonas">
        <BreadCrumb link1="/Home/EditarUsuario" infoContenidoBreadcrumb="Editar Usuario"/>
          <Contenedor className="" headerName="EDITAR USUARIO" subHeaderName="MANTENIMIENTO USUARIOS">
          <div className="class-contenedor">
                <Select OnChange={HundlerInput} disabled =  {(enabled)? "disabled" : ""} id="afiliadoId" name="afiliadoDescripcion" Placeholder="Afiliado" options={Afilicados} />
                <Select OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} id="id" name="nombre" Placeholder="Grupo" options={ListGrupo} />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioLogin} label="" type="text" id="UsuarioLogin" name="UsuarioLogin" placeholder="Nombre Usuario" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioNombres} label="" type="text" id="UsuarioNombres" name="UsuarioNombres" placeholder="Nombre Completo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioClave} label="" type="password" id="UsuarioClave" name="UsuarioClave" placeholder="Clave" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={ConfirmacionClave} label="" type="password" id="ConfirmacionClave" name="ConfirmacionClave" placeholder="Repetir Clave" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioCorreo} label="" type="text" id="UsuarioCorreo" name="UsuarioCorreo" placeholder="Correo Electronico" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioCargo} label="" type="text" id="UsuarioCargo" name="UsuarioCargo" placeholder="Usuario Cargo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioTelefono} label="" type="number" id="UsuarioTelefono" name="UsuarioTelefono" placeholder="Telefono" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={Motivo} label="" type="text" id="Motivo" name="Motivo" placeholder="Motivo" />
                <Input OnChange={HundlerInput} disabled =  {(false)? "disabled" : ""} value={UsuarioConsultas} label="" type="number" id="CantidadConsulta" name="CantidadConsulta" placeholder="Cantidad Consultas" />
                <Button type={"submit"} disabled = {(false)? "disabled" : ""} OnClick={EditarUsuario} id="BuscarPersonaID" name="BuscarPersonaName" insideNameButton="Editar usuario" FontIconClass="search" />
            </div>
        </Contenedor>
    </div>
    );
}
export default EditarUsuario
